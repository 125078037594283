import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import axios from 'axios'
import { Redirect } from "react-router-dom";
import "./CreateTheme.css"


const CreateTheme = () => {
const id = useSelector(state => state.institute.instituteID)
const [theme, setTheme] = useState("")
const [created, setCreated] = useState(false)
const [addAnother, setAddAnother] = useState(true)


const handleReturn = (evt) => {
  evt.preventDefault()
  setAddAnother(false) 
  setCreated(false)
}
 
const handleSubmit = (evt) => {
    evt.preventDefault()
    const institute={theme, id}
    axios.patch("/addTheme", {institute})
    .then(setTheme(""))
    .then(setCreated(true))
    .catch(err => alert("Something went wrong + " + err))
    }

let pageDisplay = ""
if (!created && addAnother) {
pageDisplay=
<div className="createThemeContainer">
<h1 className="createStaffHeading">Create a new theme</h1>
<form onSubmit={handleSubmit} className="themeForm">
<div className="labelFormGroup">
            <label htmlFor="firstName" className="formLabel">
              Theme
            </label>
            <input
              type="text"
              className="textInputTheme"
              name="theme"
              value={theme}
              placeholder="Enter Theme"
              onChange={(evt) => setTheme(evt.target.value)}
            />
</div>
<input type="submit" value="submit" className="themeSubmitButton"/>
</form>
</div>
} else if (created){
    pageDisplay=
    <div className="addAnotherContainer">
    <button className="themeAddAnotherButton" onClick={() => setCreated(false)}>Add Another</button>
    <button  className="themeAddAnotherButton" onClick={handleReturn}>Return Home</button>
    </div>
} else if (!addAnother && !created){
  pageDisplay=
  <Redirect to="/adminHome" />
}

return (
    pageDisplay
    )

}

export default CreateTheme