import React from 'react';
import './index.css';
import App from './App';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose} from 'redux';
import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage/session'
import ReduxThunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import staffReducer from './store/reducers/staff'
import papersReducer from './store/reducers/papers'
import instituteReducer from './store/reducers/institute'
import authReducer from './store/reducers/auth'
// import timesReducer from './store/reducers/times'

const persistConfig = {
  key: 'root',
  storage,
}

const history = createBrowserHistory()
const rootReducer= (history) => combineReducers({router: connectRouter(history), allStaff: staffReducer, auth: authReducer, papers: papersReducer, institute: instituteReducer})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const presistedReducer = persistReducer(persistConfig, rootReducer(history) )
// const store =createStore(rootReducer(history), composeEnhancer(applyMiddleware(ReduxThunk, routerMiddleware(history))))
const store =createStore(presistedReducer, composeEnhancer(applyMiddleware(ReduxThunk, routerMiddleware(history))))
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <App history={history}/>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
