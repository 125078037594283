import './DisplayStaffIndustry.css'


const DisplayStaffIndustry = ({industry}) => {
    industry.sort((a,b) => parseInt(a.industryStartYear) - parseInt(b.industryStartYear))

  
    let pageDisplay=
    <>
    {industry.map((ind, idx) => {
        return (
            <p key={idx} className="categoryItem">{`${ind.industryStartYear}: ${ind.company}, `} { ind.FundingType !== "None" && `Funded: $${ind.industryAmount} (${ind.industryFundingType})`} {ind.mta && "MTA"} {ind.cra && "CRA"}</p>
        )
    })
    }

    </>
    
      return (
        pageDisplay
      );

};

export default DisplayStaffIndustry