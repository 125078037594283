import "./DisplayStaffEngagement.css"

const DisplayEngagement = ({engagement}) => {
    let sortedEngagement
    if(engagement.length){
    sortedEngagement = engagement.sort((a,b) => parseInt(a.engagementYear) - parseInt(b.engagementYear))
    }


    let pageDisplay=
    <>
    {sortedEngagement.length ?
    sortedEngagement.map((engagement, idx) => {
        return (
            <p key={idx} className="categoryItem">{`${engagement.engagementYear}:${engagement.engagementActivity}`} {engagement.engagementType && `(${engagement.engagementType})`} {engagement.engagementEvent}</p>
        )
    })
    : <p className="categoryItem">No current grants.</p>
    }
    </>
    
      return (
        pageDisplay
      );

};

export default DisplayEngagement