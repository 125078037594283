import './DisplayStaffStudents.css'


const DisplayStaffstudents = ({students}) => {
    const current = students.filter(student => student.studentStatus === "Current")
    current.sort((a,b) => parseInt(a.studentStartYear) - parseInt(b.studentStartYear))
    const past = students.filter(student => student.studentStatus !== "Current")
    past.sort((a,b) => parseInt(a.studentStartYear) - parseInt(b.studentStartYear))
  
    let pageDisplay=
    <>
    <p className="categorySubheading">Current</p>
    {current.length ?
    current.map((student, idx) => {
        return (
            <p key={idx} className="categoryItem">{student.studentStartYear === student.studentEndYear ? `${student.studentStartYear}: ` :`${student.studentStartYear}-${student.studentEndYear || "Ongoing"}: `} {`${student.studentType}, ${student.studentName}`}</p>
        )
    }):
    <p className="categoryItem">No current students.</p>
    }
    
    <p className="categorySubheading">Past</p>
    {past.length ?
    past.map((student, idx) => {
        return (
            <p key={idx} className="categoryItem">{student.studentStartYear === student.studentEndYear ? `${student.studentStartYear}: ` :`${student.studentStartYear}-${student.studentEndYear}: `} {`${student.studentType}, ${student.studentName}, ${student.studentStatus}`}</p>
        )
    })
    :
    <p className="categoryItem">No past students.</p>
    }
    </>
    
      return (
        pageDisplay
      );

};

export default DisplayStaffstudents