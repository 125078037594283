import "./DisplayInstitutePublicationStats.css"

const DisplayPublicationStats = ({pubsLast7days, pubsThisYear, allPubs}) => {
return (

 
<div>    
          <h2 className="sideBarSummaryHeading">Summary</h2>
          <div className="instituteStatAndLabel">
            <div className="instituteStat">
              <p className="dataItem">{`${pubsLast7days}`}</p>
            </div>
            <p className="instituteStatLabel">Papers this week</p>
          </div>

          <div className="instituteStatAndLabel">
            <div className="instituteStat">
              <p className="dataItem">{`${pubsThisYear}`}</p>
            </div>
            <p className="instituteStatLabel">Papers this year</p>
          </div>

          <div className="instituteStatAndLabel">
            <div className="instituteStat">
              <p className="dataItem">{`${allPubs}`}</p>
            </div>
            <p className="instituteStatLabel">Papers overall</p>
          </div>
        
  </div>

)
}

export default DisplayPublicationStats