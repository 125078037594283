import "./App.css";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import Menubar from "./Menubar";
import Home from "./displayComponents/Home";
import AuthLogin from "./authComponents/AuthLogin";
import AuthLogout from "./authComponents/AuthLogout";
import AuthRegister from "./authComponents/AuthRegister";
import ForgotPasswordRegister from "./authComponents/ForgotPasswordRegister";
import ForgotPasswordSendLink from "./authComponents/ForgotPasswordSendLink";
import AuthRegisterAdmin from "./authComponents/AuthRegisterAdmin";
import AdminHome from "./adminComponents/AdminHome";
import CreateStaffMember from "./adminComponents/CreateStaffMember";
import CreateAdmin from "./adminComponents/CreateAdmin";
import EditStaffMember from "./adminComponents/EditStaffMember";
import CreateInstitute from "./adminComponents/CreateInstitute";
import CreateTheme from "./adminComponents/CreateTheme";
import CreateResource from "./adminComponents/CreateResource";
import CreateReport from "./displayComponents/CreateReport";
import DisplayResearchStaffPortal from "./displayComponents/DisplayResearchStaffPortal";
import CreatePublicationList from "./adminComponents/CreatePublicationList";
import CreateGrants from "./adminComponents/CreateGrants";
import CreateStudents from "./adminComponents/CreateStudents";
import CreateIndustry from "./adminComponents/CreateIndustry";
import CreateCommittees from "./adminComponents/CreateCommittees";
import UploadAllData from "./adminComponents/UploadAllData";
import UpdateData from "./adminComponents/UpdateData";
import LabThemeSelect from "./displayComponents/LabThemeSelect";
import DisplayStaffMember from "./displayComponents/DisplayStaffMember";
import DisplayTheme from "./displayComponents/DisplayTheme";
import AdminSelectedStaffMember from "./adminComponents/AdminSelectedStaffMember";

const App = ({ history }) => {
  return (
    <div className="App">
      <ConnectedRouter history={history}>
        <Menubar />
        <Switch>
          <Route
            exact
            path="/register"
            render={(props) => <AuthRegister {...props} />}
          />
          <Route
            exact
            path="/adminRegister"
            render={(props) => <AuthRegisterAdmin {...props} />}
          />
          <Route
            exact
            path="/login"
            render={(props) => <AuthLogin {...props} />}
          />
          <Route
            exact
            path="/logout"
            render={(props) => <AuthLogout {...props} />}
          />
          <Route
            exact
            path="/forgotPasswordRegister"
            render={(props) => <ForgotPasswordRegister {...props} />}
          />
          <Route
            exact
            path="/forgotPasswordSendLink"
            render={(props) => <ForgotPasswordSendLink {...props} />}
          />
          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route
            exact
            path="/adminHome"
            render={(props) => <AdminHome {...props} />}
          />
          <Route
            exact
            path="/adminSelectedStaffMember"
            render={(props) => <AdminSelectedStaffMember {...props} />}
          />
          <Route
            exact
            path="/createInstitute"
            render={(props) => <CreateInstitute {...props} />}
          />
          <Route
            exact
            path="/createStaffMember"
            render={(props) => <CreateStaffMember {...props} />}
          />
          <Route
            exact
            path="/createResource"
            render={(props) => <CreateResource {...props} />}
          />
          <Route
            exact
            path="/createAdmin"
            render={(props) => <CreateAdmin {...props} />}
          />
          <Route
            exact
            path="/editStaffMember"
            render={(props) => <EditStaffMember {...props} />}
          />
          <Route
            exact
            path="/createTheme"
            render={(props) => <CreateTheme {...props} />}
          />
          <Route
            exact
            path="/createPublicationList"
            render={(props) => <CreatePublicationList {...props} />}
          />
          <Route
            exact
            path="/createGrants"
            render={(props) => <CreateGrants {...props} />}
          />
          <Route
            exact
            path="/createStudents"
            render={(props) => <CreateStudents {...props} />}
          />
          <Route
            exact
            path="/createIndustry"
            render={(props) => <CreateIndustry {...props} />}
          />
          <Route
            exact
            path="/createCommittees"
            render={(props) => <CreateCommittees {...props} />}
          />
          <Route
            exact
            path="/uploadAllData"
            render={(props) => <UploadAllData {...props} />}
          />
          <Route
            exact
            path="/updateData"
            render={(props) => <UpdateData {...props} />}
          />
          <Route
            exact
            path="/labThemeSelect"
            render={(props) => <LabThemeSelect {...props} />}
          />
          <Route
            exact
            path="/displayStaffMember"
            render={(props) => <DisplayStaffMember {...props} />}
          />
          <Route
            exact
            path="/displayTheme"
            render={(props) => <DisplayTheme {...props} />}
          />
          <Route
            exact
            path="/createReport"
            render={(props) => <CreateReport {...props} />}
          />
          <Route
            exact
            path="/researcherPortal"
            render={(props) => <DisplayResearchStaffPortal {...props} />}
          />
        </Switch>
      </ConnectedRouter>
    </div>
  );
};

export default App;
