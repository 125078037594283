import './DisplayStaffCommittees.css'


const DisplayStaffCommittees = ({internal}) => {
    const current = internal.filter(activity => activity.activityEndYear === "Current")
    current.sort((a,b) => parseInt(a.activityStartYear) - parseInt(b.activityStartYear))
    const past = internal.filter(activity => activity.activityEndYear !== "Current")
    past.sort((a,b) => parseInt(a.activityStartYear) - parseInt(b.activityStartYear))
  
    let pageDisplay=
    <>
    <p className="categorySubheading">Current</p>
    {current.length ? 
    current.map((activity, idx) => {
        return (
            <p key={idx} className="categoryItem">{`${activity.activityStartYear}-Current: ${activity.activityRole}: ${activity.activityDescription}`}</p>
        )
    })
    :
    <p className="categoryItem">No current internal activities </p>
    } 

<p className="categorySubheading">Past</p>
{past.length ?    
    past.map((activity, idx) => {
        return (
            <p key={idx} className="categoryItem">{parseInt(activity.activityStartYear) === parseInt(activity.activityEndYear) ? `${activity.activityStartYear}` : `${activity.activityStartYear}-${activity.activityEndYear}`}: {activity.activityRole}, {activity.activityDescription}</p>
        )
    })
    :
    <p className="noItem">No past internal activities </p>
    } 
    </>
    
      return (
        pageDisplay
      );

};

export default DisplayStaffCommittees