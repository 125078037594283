import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import ReportingDropdown from "./ReportingDropdown";
import * as FileSaver from "file-saver";
import * as xlsx from "xlsx";
import dayjs from "dayjs";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import {
  prepareInstituteData,
  prepareStaffData,
  prepareThemeData,
  prepareReportPapers,
} from "../utilityComponents/CreateReportFunctions";
import * as allActions from "../store/actions/papers";
import "./CreateReport.css";

const CreateReport = () => {
  const todayInputFormatted = dayjs().format("YYYY-MM-DD");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(todayInputFormatted);
  const [getReportPapers, setGetReportPapers] = useState(false);
  const [reportCreated, setReportCreated] = useState(false);

  const today = dayjs().format("DD/MM/YYYY");
  const staffPubs = useSelector((state) => state.allStaff.staff);
  const staffPubsSorted = staffPubs.sort(
    (a, b) => b.publications.length - a.publications.length
  );
  const themeDetails = useSelector((state) => state.institute.themeDetails);
  const themesPubsByYear = useSelector(
    (state) => state.institute.themesPubsByYear
  );
  const themeDetailsSorted = themeDetails.sort(
    (a, b) => b.totalPapers.length - a.totalPapers.length
  );
  const allInstituteData = useSelector((state) => state.institute);
  const reportList = useSelector((state) => state.institute.reportList);

  const dispatch = useDispatch();

  const handleStartDate = (evt) => {
    setStartDate(evt.target.value);
  };

  const handleEndDate = (evt) => {
    setEndDate(evt.target.value);
  };

  const handleTimeFrame = (evt) => {
    const calcStartDate = dayjs()
      .subtract(evt.target.value, "day")
      .format("YYYY-MM-DD");
    setStartDate(calcStartDate);
  };

  const handleReportPapers = () => {
    if (!startDate) {
      alert("Must enter start date for report");
    } else {
      setGetReportPapers(true);
    }
  };

  // const handleReportReset () => {
  //     dispatch(allActions.resetReport())
  // }

  const prepareData = () => {
    const workBook = xlsx.utils.book_new();

    // Prepare and append staff data to workbook
    const staffSheetCols = [
      "Name",
      "Papers Total",
      "Papers This Year",
      "Grants Total ($M)",
      "Students",
      "Industry Collaborations",
    ];
    const staffData = prepareStaffData(staffPubsSorted);
    const staffWorkSheetData = [staffSheetCols, ...staffData];

    const staffWorkSheet = xlsx.utils.aoa_to_sheet(staffWorkSheetData);
    xlsx.utils.book_append_sheet(workBook, staffWorkSheet, "Staff Data");

    // Prepare and append theme data to workbook
    const themeSheetCols = [
      "Theme",
      "Papers Total",
      "Papers This Year",
      "Average Papers per Group",
    ];
    const themeData = prepareThemeData(themeDetailsSorted, themesPubsByYear);
    const themeWorkSheetData = [themeSheetCols, ...themeData];
    const themeWorkSheet = xlsx.utils.aoa_to_sheet(themeWorkSheetData);
    xlsx.utils.book_append_sheet(workBook, themeWorkSheet, "Theme Data");

    // Prepare and append institute data to workbook
    const instituteSheetCols = [
      "Papers Total",
      "Papers This Year",
      "Papers This Week",
      "Papers in reporting period",
    ];
    const instituteData = prepareInstituteData(allInstituteData, reportList);
    const instituteWorkSheetData = [instituteSheetCols, ...instituteData];
    const instituteWorkSheet = xlsx.utils.aoa_to_sheet(instituteWorkSheetData);
    xlsx.utils.book_append_sheet(
      workBook,
      instituteWorkSheet,
      "Institute Data"
    );

    // Prepare and append institute report papers to workbook
    const reportPapersSheetCols = [
      "Title",
      "Journal",
      "Authors",
      "PMID",
      "doi",
    ];
    const reportPapersData = prepareReportPapers(reportList);
    const reportPapersWorkSheetData = [
      reportPapersSheetCols,
      ...reportPapersData,
    ];
    const reportPapersWorkSheet = xlsx.utils.aoa_to_sheet(
      reportPapersWorkSheetData
    );
    xlsx.utils.book_append_sheet(
      workBook,
      reportPapersWorkSheet,
      "Reporting period papers"
    );

    createSheet(workBook);
    setGetReportPapers(false);
    setStartDate("");
    setEndDate(todayInputFormatted);
    setReportCreated(true);
  };

  const createSheet = (workBook) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    workBook.Props = { Title: `Report ${today}` };
    const excelBuffer = xlsx.write(workBook, {
      bookType: "xlsx",
      type: "array",
    });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, "Institute Report" + fileExtension);
  };

  useEffect(() => {
    if (getReportPapers) {
      setLoading(true);
      dispatch(allActions.getReportPapers(startDate, endDate)).then(() =>
        setLoading(false)
      );
    } else {
      return;
    }
  }, [getReportPapers]);

  useEffect(() => {
    if (reportCreated) {
      dispatch(allActions.resetReport());
      setStartDate("");
      setEndDate(todayInputFormatted);
    } else {
      return;
    }
  }, [reportCreated]);

  let pageDisplay;

  if (reportCreated) {
    alert("Report has been downloaded...");
    pageDisplay = <Redirect push to="/labThemeSelect" />;
  } else {
    pageDisplay = (
      <div className="createReportContainer">
        <div>
          <div className="headingInstructionContainer">
            <h1 className="createReportHeading">Institute Stats Reports</h1>
            <p className="createReportSubheading">
              All data related to staff,themes and the institute will be
              automatically included.
            </p>

            <p className="createReportSubheading">
              Enter the reporting dates to include deatails of the latest papers
              for a specific report period.
            </p>
          </div>

          <div>
            <p className="timeFrameSubheading">By timeframe...</p>
          </div>
        </div>

        {loading ? (
          <div className="loader">
            <Loader color="#2ca3da" height={80} width={80} />
            <h2 className="loaderLabel">Fetching papers...</h2>
          </div>
        ) : (
          <div className="inputButtonsContainer">
            <ReportingDropdown handleTimeFrame={handleTimeFrame} />

            {/* <div className="dateInputContainer">        
            <div  className="dateInputLabelContainer">
            <label htmlFor="periodDropDown" className="dateInputLabel">Time Frame</label>
            <select onChange={handleTimeFrame} defaultValue={timeFrame} className="timeFrameDropdown">
              <option value="" disabled>Select a timeframe to collect papers...</option>
              <option value={7}>Last 7 days</option>
              <option value={14}>Last 14 days</option>
              <option value={30}>Last 1 month</option>
              <option value={60}>Last 2 months</option>
              <option value={90}>Last 3 months</option>
              <option value={120}>Last 4 months</option>
              <option value={180}>Last 6 months</option>
              <option value={365}>Last year</option>
            </select>
            </div>
            </div> */}

            <div>
              <p className="timeFrameSubheading">
                Or by entering specific start and end dates...
              </p>
            </div>

            <div className="dateInputContainer">
              <div className="dateInputLabelContainer">
                <label htmlFor="startDate" className="dateInputLabel">
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={handleStartDate}
                  className="dateInput"
                />
              </div>

              <div className="dateInputLabelContainer">
                <label htmlFor="EndDate" className="dateInputLabel">
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={handleEndDate}
                  className="dateInput"
                />
              </div>
            </div>

            {!reportList.length ? (
              <button onClick={handleReportPapers} className="getPapersButton">
                FETCH REPORT PAPERS
              </button>
            ) : null}

            {reportList.length ? (
              <button
                onClick={() => prepareData()}
                className="createReportButton"
              >
                CREATE REPORT
              </button>
            ) : null}
          </div>
        )}
      </div>
    );
  }

  return pageDisplay;
};

export default CreateReport;
