import React, { useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { Redirect } from "react-router-dom";
import "./EditStaffMember.css";
import { useSelector } from "react-redux";

const EditStaffMember = (props) => {
  const selectedStaffMember = props.location.state.selectedStaffMember;
  const convertedDate = dayjs(selectedStaffMember.dateStarted).format(
    "YYYY-MM-DD"
  );
  const themes = useSelector(
    (state) => state.institute.themes
  );

  const [gender, setGender] = useState(selectedStaffMember.gender);
  const [firstName, setFirstName] = useState(selectedStaffMember.firstName);
  const [lastName, setLastName] = useState(selectedStaffMember.lastName);
  const [position, setPosition] = useState(selectedStaffMember.position);
  const [email, setEmail] = useState(selectedStaffMember.email);
  const [theme, setTheme] = useState(selectedStaffMember.theme);
  const [laboratory, setLaboratory] = useState(selectedStaffMember.laboratory);
  const [dateStarted, setDateStarted] = useState(convertedDate);
  const [status, setStatus] = useState(selectedStaffMember.status || "current");
  const [permissions, setPermissions] = useState(
    selectedStaffMember.permissions
  );
  const [created, setCreated] = useState(false);
  // const [photoPath, setPhotoPath] = useState(selectedStaffMember.photoPath);
  const [file, setFile] = useState("");

  const handleSubmit = (photoPath) => {
    // evt.preventDefault();
  
    const staffMember = {
      id: selectedStaffMember._id,
      firstName,
      lastName,
      gender,
      email,
      position,
      theme,
      laboratory,
      dateStarted,
      photoPath,
      permissions,
      status
    };

    axios
      .post("/updateStaffMember", { staffMember })
      .then((response) => alert("Staff member updated"))
      .then(setCreated(true))
      .catch((err) => console.log("error"));
  };

  const handlePhotoSubmit = (evt) => {
    evt.preventDefault();
    console.log("stjkkajsd" )
    let url = "/addStaffPhoto";

    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(url, formData, config)
      // .then(response => setPhotoPath(response.data.photoPath))
      .then((response) => handleSubmit(response.data.photoPath));
  };

  let pageDisplay;

  if (!created) {
    pageDisplay = (
      <div className="createStaffContainer">
        <h1 className="createStaffHeading">Edit staff member</h1>
        <p className="createStaffSubHeading">
          Edit details/ upload new photo for selected staff member
        </p>
        <form onSubmit={handlePhotoSubmit}>
          <div className="labelFormGroup">
            <label htmlFor="firstName" className="formLabel">
              First Name
            </label>
            <input
              required
              type="text"
              className="textInputStaff"
              name="firstName"
              value={firstName}
              placeholder="Enter First Name"
              onChange={(evt) => setFirstName(evt.target.value)}
            />
          </div>
          <div className="labelFormGroup">
            <label htmlFor="lastName" className="formLabel">
              Last Name
            </label>
            <input
              required
              type="text"
              className="textInputStaff"
              name="lastName"
              value={lastName}
              placeholder="Enter Last Name"
              onChange={(evt) => setLastName(evt.target.value)}
            />
          </div>

          <div className="labelFormGroup">
            <label htmlFor="firstName" className="formLabel">
              Email
            </label>
            <input
              required
              type="email"
              className="textInputStaff"
              name="email"
              value={email}
              placeholder="Enter email address"
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </div>

          <div className="labelFormGroup">
            <label htmlFor="dateStarted" className="formLabel">
              Date Started
            </label>
            <input
              required
              type="date"
              name="dateStarted"
              value={dateStarted}
              onChange={(evt) => setDateStarted(evt.target.value)}
              className="dateInputStaff"
            />
          </div>

          <div className="labelFormGroup">
            <label htmlFor="gender" className="formLabel">
              Gender
            </label>
            <select
              required
              name="gender"
              value={gender}
              onChange={(evt) => setGender(evt.target.value)}
              className="dropdownStaff"
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="labelFormGroup">
            <label htmlFor="position" className="formLabel">
              Position
            </label>
            <select
              required
              name="position"
              value={position}
              onChange={(evt) => setPosition(evt.target.value)}
              className="dropdownStaff"
            >
              <option>Theme Leader</option>
              <option>Laboratory Head</option>
              <option>Group Leader</option>
            </select>
          </div>

          {/* <div className="formGroup">
            <div className="labelFormGroup">
              <label htmlFor="theme" className="formLabel">
                Theme
              </label>
              <select
                required
                name="theme"
                value={theme}
                onChange={(evt) => setTheme(evt.target.value)}
                className="dropdownStaff"
              >
                <option>Cancer and Inflammation</option>
                <option>Cancer Immunobiology</option>
                <option>Gastrointestinal Cancer</option>
                <option>Translational Breast Cancer theme</option>
                <option>Tumour Targeting</option>
                <option>Platform Technology</option>
              </select>
            </div>
          </div> */}

          <div className="formGroup">    
        <div className="labelFormGroup">
        <label htmlFor="theme" className="formLabel">Theme</label>
        <select
        required
          name="theme"
          value={theme}
          onChange={(evt) => setTheme(evt.target.value)}
          className="dropdownStaff"
        >
          <option diabled>Select theme...</option>
          {themes.map(theme => <option>{theme}</option>)}
        </select>
        </div>
        </div>





          <div className="labelFormGroup">
            <label htmlFor="laboratory" className="formLabel">
              Laboratory
            </label>
            <input
              required
              type="text"
              name="laboratory"
              placeholder="Enter labortaory name"
              value={laboratory}
              onChange={(evt) => setLaboratory(evt.target.value)}
              className="textInputStaff"
            />
          </div>

          <div className="formGroup">
            <div className="labelFormGroup">
              <label htmlFor="permissions" className="formLabel">
                Permissions
              </label>
              <select
                required
                name="permissions"
                value={permissions}
                onChange={(evt) => setPermissions(evt.target.value)}
                className="dropdownStaff"
              >
                <option disabled>Select permissions...</option>
                <option>researcher</option>
                <option>admin</option>
                <option>exec</option>
                <option>director</option>
              </select>
            </div>
          </div>

          <div className="formGroup">
            <div className="labelFormGroup">
              <label htmlFor="status" className="formLabel">
                Status
              </label>
              <select
                required
                name="theme"
                value={status}
                onChange={(evt) => setStatus(evt.target.value)}
                className="dropdownStaff"
              >
                <option diabled>Select status...</option>
                <option>current</option>
                <option>past</option>
              </select>
            </div>
          </div>

          <div className="uploadForm">
            <label htmlFor="photoUpload" className="formLabel">
              Select a photo to upload this staff member
            </label>
            <input
              type="file"
              id="photoUpload"
              className="uploadFileInput"
              name="photo"
              onChange={(evt) => setFile(evt.target.files[0])}
            />
         <input type="submit" value="Submit" className="createStaffSubmit" />
          </div>
          
        </form>
      </div>
    );
  } else {
    pageDisplay = <Redirect to="/adminHome" />;
  }

  return pageDisplay;
};

export default EditStaffMember;
