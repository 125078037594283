import { useSelector } from "react-redux";

import "./PublicationDisplay.css";

const PublicationDisplay = ({ paperList, claimPaper, addTag }) => {
  const pubIds = useSelector((state) => state.allStaff.loginStaffMember.pubIds);
 
  
  let listDisplay = "";

  if (paperList.length) {
    listDisplay = paperList.map((data) => {
      let volume = "";
      if (data.volume === "") {
        volume = " volume/pages not yet available";
      } else {
        volume = `${data.volume}: `;
      }

      return (
        <div className="paperlistItem" key={data.id}>
          <span className="paperDetailsSpan">
            <span className="title">{`${data.title} `}</span>
            <span className="authors">{`${data.authors}, `}</span>
            <span className="year">{`(${data.pubdate}), `}</span>
            <span className="journal">{`${data.journal},  `}</span>
            <span className="volume">{`${volume} `}</span>
            <span className="pages">{`${data.pages},  `}</span>
            <span className="doi">{`${data.doi}, `}</span>
            <span className="pmid">
              PMID:{" "}
              <a
                href={`https://www.ncbi.nlm.nih.gov/pubmed/${data.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.id}.
              </a>
            </span>
          </span>
          
     
          <div className={pubIds.includes(data.id)? "checkInputTagsGroupClaimed": "checkInputTagsGroup"} >   
              
               {pubIds.includes(data.id) ? 
                <p className="claimedMessage">Paper is in your publication list.</p>
                :
                

                <div className="tagInputLabel">
                    <input
                     className="tagInput"
                      type="checkbox"
                      value={data.claimed}
                      checked={data.claimed}
                      onChange={() => claimPaper(data.id)}
                    />
                  <label>Claim</label>
                </div>
          }
                {data.claimed ? (
                  <div className="tagInputLabel">
                    
                      <input
                        className="tagInput"
                        name="First/Last Author"
                        type="checkbox"
                        value="firstLastAuthor"
                        checked={data.tags.some(
                          (el) => el.tagName === "First/Last Author"
                        )}
                        onChange={(evt) =>
                          addTag(data.id, {
                            style: evt.target.value,
                            tagName: evt.target.name,
                            
                          })
                        }
                      />
                  <label>First/Last Author</label>

                    
                      <input
                      className="tagInput"
                        name="Industry Collaboration"
                        type="checkbox"
                        value="industry"
                        checked={data.tags.some(
                          (el) => el.tagName === "Industry Collaboration"
                        )}
                        onChange={(evt) =>
                          addTag(data.id, {
                            style: evt.target.value,
                            tagName: evt.target.name,
                          })
                        }
                      />
                      <label>Industry Collaboration</label>

                    
                      <input
                      className="tagInput"
                        name="International Collaboration"
                        type="checkbox"
                        value="international"
                        checked={data.tags.some(
                          (el) => el.tagName === "International Collaboration"
                        )}
                        onChange={(evt) =>
                          addTag(data.id, {
                            style: evt.target.value,
                            tagName: evt.target.name,
                          })
                        }
                      />
                      <label>International Collaboration</label>
                     

                    
                      <input
                      className="tagInput"
                        name="Clinical Trial"
                        type="checkbox"
                        value="clinical"
                        checked={data.tags.some(
                          (el) => el.tagName === "Clinical Trial"
                        )}
                        onChange={(evt) =>
                          addTag(data.id, {
                            style: evt.target.value,
                            tagName: evt.target.name,
                          })
                        }
                      />
                      <label>Clinical Trial</label>
                  </div>
                ) : null}
              </div>
              </div>
      );
    });
  }

  return <div className="paperListContainer">{listDisplay}</div>;
};

export default PublicationDisplay;
