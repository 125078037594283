import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Loader from "react-loader-spinner";
import * as allActions from "../store/actions/auth";
import "./auth.css";
import { Redirect } from "react-router-dom";

const ForgotPasswordSendLink = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [hash, setHash] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setSubmitted(true);
  };

  useEffect(() => {
    if (hash) {
      axios
        .post("/sendResetEmail", { email, hash })
        .then((response) => setCreated(true))
        .then((response) => setLoading(false))
        .catch((err) => console.log("error"));
    }
  }, [hash]);

  useEffect(() => {
    if (submitted) {
      setLoading(true);
      const staffMember = { email };
      axios
        .post("/createNewPasswordLink", { staffMember })
        .then((response) => setHash(response.data.hash))
        .catch((err) => console.log("error"));
    }
  }, [submitted]);

  let pageDisplay = "";
  if (loading) {
    pageDisplay = (
      <div className="loaderContainer">
        <Loader
          type="ThreeDots"
          color="#2ca3da"
          height={80}
          width={80}
          className="loader"
        />
        <h1 className="loaderLabel">Loading Data...</h1>
      </div>
    );
  } else if (!loading && !created) {
    pageDisplay = (
      <>
        <form onSubmit={handleSubmit} className="authForm">
          <h4 className="formHeading">Reset Password</h4>
          <div className="labelField">
            <label htmlFor="username" className="loginFormLabel">
              Email
            </label>
            <input
              type="email"
              onChange={(evt) => setEmail(evt.target.value)}
              name="username"
              value={email}
              className="loginForm"
              placeholder="Enter your ONJCRI email for password reset link"
            />
          </div>

          <button type="submit" className="loginFormButton">
            Submit
          </button>
        </form>
      </>
    );
  } else if (created) {
    pageDisplay = (
      <div className="resetPasswordMessage">
        <p>
          Your request to reset your password has been processed. Check your
          email for a "Reset Password" link.
        </p>
        <p>
          If you do not receive this email within the next hour, please contact
          your administrator.
        </p>
      </div>
    );
  }

  return (
    <div className="authFormContainer">
      <img src="LOGO.png" alt="logo" className="logo" />
      <h3 className="loginHeading1">ONJCRI</h3>
      <h3 className="loginHeading2">Performance & Impact Portal</h3>
      {pageDisplay}
    </div>
  );
};

export default ForgotPasswordSendLink;
