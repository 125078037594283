import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import "./DisplayStaffMember.css";
import DisplayStaffMemberHeader from "./DisplayStaffMemberHeader";
import DisplayStaffPublications from "./DisplayStaffPublications";
import DisplayStaffCommittees from "./DisplayStaffCommittees";
import DisplayStaffStudents from "./DisplayStaffStudents";
import DisplayStaffGrants from "./DisplayStaffGrants";
import DisplayStaffEngagement from "./DisplayStaffEngagement";
import DisplayStaffPatents from "./DisplayStaffPatents";
import DisplayStaffClinical from "./DisplayStaffClinical";
import DisplayStaffIndustry from "./DisplayStaffIndustry";
import StaffDataSummary from "./StaffDataSummary" 
import StaffPubsThisYearChart from "../chartComponents/StaffPubsThisYearChart";
import StaffPubsTotalChart from "../chartComponents/StaffPubsTotalChart"
import StaffPubsPerYearChart from "../chartComponents/StaffPubsPerYearChart"
import Loader from "react-loader-spinner";
import * as instActions from "../store/actions/institute";
import * as staffActions from "../store/actions/staff";


const DisplayStaffMember = (props) => {
  const instStartYear = useSelector(
    (state) => state.institute.instituteStartYear
  );
  const [displayData, setDisplayData] = useState(false)
  const [loading, setLoading] = useState(false);

  const {
    firstName,
    lastName,
    photoPath,
    laboratory,
    program,
    position,
    dateStarted,
    publications,
    grants,
    students,
    industry,
    internal,
    engagement,
    patents,
    clinical
  } = props.location.state.selectedStaffMember;

  const pubNumber= publications?.length
  const internalNumber= internal?.length
  const studentNumber = students?.length
  const industryNumber = industry?.length
  const engagementNumber = engagement?.length
  const patentNumber = patents?.length
  const clinicalNumber = clinical?.length
  const grantNumberMill =grants.reduce((a,b) => a + parseInt(b.grantAmount), 0)
  const grantNumber = `${(grantNumberMill/Math.pow(10,6)).toFixed(1)}`
  const staffPubs = useSelector((state) => state.allStaff.staff);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
      dispatch(staffActions.getAllStaff())
        .then(() =>
          dispatch(
            instActions.getAllPaperData(
              process.env.REACT_APP_INSTITUTE_NAME,
              instStartYear
            )
          )
        )
        .then(() => setLoading(false))
  }, []);





  let pageDisplay;
if (loading) {
    pageDisplay = 
      <>
        <Loader type="ThreeDots" color="#2ca3da" height={80} width={80} className="pubsearchLoader"/>
        <h1 className="loaderLabel">Loading staff member...</h1>
      </>;
  } else if (firstName) {
    pageDisplay = (
      <>
        <div className="staffHeadSection"> 
        <DisplayStaffMemberHeader
          staffDetails={{
            firstName,
            lastName,
            photoPath,
            laboratory,
            program,
            position,
            dateStarted,

          }}
        /> 
        </div>

        <div className="displayDataContainer">
          
          <div className="staffSidebarContainer">
          
            <div className="nonPublicationsItem">
         
            <h3 className="categoryHeading">Internal Activities</h3>
           
            {internal?.length ?    
            <DisplayStaffCommittees internal={internal} />
             :  <p className="noItemMessage">No internal activities recorded</p>
            }
          </div>
    
          

          <div className="nonPublicationsItem">
            <h3 className="categoryHeading">Grants</h3>
            {grants?.length ? 
            <DisplayStaffGrants grants={grants} />
             : <p className="noItemMessage">No grants recorded</p>
            }
          </div>

          <div className="nonPublicationsItem">
            <h3 className="categoryHeading">Students</h3>
            {students?.length ? 
            <DisplayStaffStudents students={students} />
             : <p className="noItemMessage">No students recorded</p>
            }
          </div>

          <div className="nonPublicationsItem">
            <h3 className="categoryHeading">Engagement</h3>
            {engagement?.length ? 
            <DisplayStaffEngagement engagement={engagement} />
             : <p className="noItemMessage">No engagement recorded</p>
            }
            </div>

          <div className="nonPublicationsItem">
            <h3 className="categoryHeading">Industry collaboration</h3>
            {industry?.length ? 
            <DisplayStaffIndustry industry={industry} />
             : <p className="noItemMessage">No industry involvement recorded</p>
            }
          </div>

          <div className="nonPublicationsItem">
            <h3 className="categoryHeading">Patents</h3>
            {patents?.length ? 
            <DisplayStaffPatents patents={patents} />
             : <p className="noItemMessage">No patents recorded</p>
            }
          </div>

          <div className="nonPublicationsItem">
            <h3 className="categoryHeading">Clinical Collaboration</h3>
            {clinical?.length ? 
            <DisplayStaffClinical clinical={clinical} />
             : <p className="noItemMessage">No clinical collaborations recorded</p>
            }
          </div>

          </div>

          <div className="publicationsContainer">
                    
        <StaffDataSummary 
             pubNumber={pubNumber}
             internalNumber={internalNumber}
             grantNumber={grantNumber}
             studentNumber={studentNumber}
             industryNumber={industryNumber}
             patentNumber={patentNumber}
             clinicalNumber={clinicalNumber}
             engagementNumber={engagementNumber}
        />
          
          
          <button className="displayDataButton" onClick={() => setDisplayData(!displayData)}>{!displayData ? `Display Staff Comparison Charts` : `Hide Graphs`}</button>
          

            
         {staffPubs.length && displayData ? (
          <div className="staffStats">
            <div className="statCharts">
            <StaffPubsTotalChart staffPubs={staffPubs} staffColor={firstName}/>
            <StaffPubsThisYearChart staffPubs={staffPubs} staffColor={firstName} />
            <StaffPubsPerYearChart staffPubs={staffPubs} staffColor={firstName} />
            </div>
          </div>
        ) : null}


          <h3 className="categoryHeading">Publications</h3>
           <div className="paperList">
            {publications.length ? (
              <DisplayStaffPublications paperList={publications} displayDates={true}/>
            ) : <p>No publications entered in database yet.</p>}
          </div>
        </div>
        </div>
      </>
    );
  }

  return pageDisplay;
};

export default DisplayStaffMember;
