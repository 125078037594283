import axios from "axios";

export const REGISTER = "REGISTER";
export const ADMIN_REG = "ADMIN_REG";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_STAFF_MEMBER = "LOGIN_STAFF_MEMBER";
export const RESET_AUTH = "RESET_AUTH";
export const RESET_LOGIN_STAFF_MEMBER = "RESET_LOGIN_STAFF_MEMBER";

export const login = (user) => {
  return async (dispatch) => {
    await axios
      .post("/login", user)
      .then((response) => {
        if (response.data.token) {
          let token = response.data.token;
          // localStorage.setItem("auth-token", token)
          dispatch({
            type: LOGIN,
            userId: response.data.user.id,
            token,
            permissions: response.data.user.permissions,
            isLoggedIn: true,
          });
          dispatch({
            type: LOGIN_STAFF_MEMBER,
            loginStaffMember: response.data.user,
          });
        }
      })
      .catch((err) => {
        alert("There was an issue logging in - try again");
        dispatch({ type: LOGIN, userId: "", isLoggedIn: false });
      });
  };
};

export const loginAdmin = (user) => {
  return async (dispatch) => {
    await axios
      .post("/loginAdmin", user)
      .then((response) => {
        if (response.data.msg === "adminReg") {
          dispatch({
            type: ADMIN_REG,
            adminReg: false,
          });
        }
        if (response.data.token) {
          let token = response.data.token;
          // localStorage.setItem("auth-token", token)
          dispatch({
            type: LOGIN,
            userId: response.data.user.id,
            token,
            permissions: response.data.user.permissions,
            isLoggedIn: true,
          });
          dispatch({
            type: LOGIN_STAFF_MEMBER,
            loginStaffMember: response.data.user,
          });
        }
      })
      .catch((err) => {
        alert("There was an issue logging in - try again");
        dispatch({ type: LOGIN, userId: "", isLoggedIn: false });
      });
  };
};

export const register = (user) => {
  return async (dispatch) => {
    await axios
      .post("/register", user)
      .then((response) => {
        if (response.data.userId) {
          dispatch({
            type: REGISTER,
            registered: true,
          });
        }
      })
      .catch((err) => {
        alert("There was an issue logging in - try again");
        dispatch({ type: LOGIN, userId: "", isLoggedIn: false });
      });
  };
};

export const registerAdmin = (user) => {
  return async (dispatch) => {
    await axios
      .post("/registerAdmin", user)
      .then((response) => {
        if (response.data.userId) {
          dispatch({
            type: REGISTER,
            registered: true,
          });
        }
      })
      .catch((err) => {
        alert("There was an issue logging in - try again");
        dispatch({ type: LOGIN, userId: "", isLoggedIn: false });
      });
  };
};

export const resetAuth = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_AUTH });
    dispatch({ type: RESET_LOGIN_STAFF_MEMBER });
  };
};

export const logout = () => {
  return { type: LOGOUT };
};
