import './DisplayStaffGrants.css'


const DisplayStaffGrants = ({grants}) => {
    
    const thisYear = parseInt(new Date().getFullYear());

    const current = grants.filter(grant => parseInt(grant.grantEndYear) >= thisYear)
    current.sort((a,b) => parseInt(a.grantStartYear) - parseInt(b.grantStartYear))
    const past = grants.filter(grant => parseInt(grant.grantEndYear) < thisYear)
    past.sort((a,b) => parseInt(a.grantStartYear) - parseInt(b.grantStartYear))
  
    let pageDisplay=
    <>
    <p className="categorySubheading">Current</p>
    {current.length?
    current.map((grant, idx) => {
        return (
            <p key={idx} className="categoryItem">{grant.grantStartYear === grant.grantEndYear ? `${grant.grantStartYear}`: `${grant.grantStartYear}-${grant.grantEndYear}`}{ `: ${grant.grantRole} ${grant.fundingBody} ${grant.grantType}, $${grant.grantAmount}`}</p>
        )
    })
    : <p className="categoryItem">No current grants.</p>
    }

    <p className="categorySubheading">Past</p>
    {past.length ?
    past.map((grant, idx) => {
        return (
            <p key={idx} className="categoryItem">{grant.grantStartYear === grant.grantEndYear ? `${grant.grantStartYear}`: `${grant.grantStartYear}-${grant.grantEndYear}`}{ `: ${grant.grantRole} ${grant.fundingBody} ${grant.grantType}, $${grant.grantAmount}`}</p>
        )
    })
    : <p className="categoryItem">No past grants.</p>
    }
    </>
    
      return (
        pageDisplay
      );

};

export default DisplayStaffGrants