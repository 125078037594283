import "./ThemeSidebarSummary.css"


const ThemeSidebarSummary = ({themeDetails, papersThisYear}) => {

const totalPapers = themeDetails.totalPapers.length
const papersPerGroup = (totalPapers / themeDetails.members.length).toFixed(1)
 
 return (
<div className="sideBarContainer">
    <h2 className="themeSideBarSummaryHeading">Summary</h2>

    <div className="summaryItemContainer">
    <div className="summaryItemNumber">
    <p className="number">{totalPapers}</p>  
    </div>
    <p className="summaryItemLabel">Total Papers</p>
    </div>


 
        <div className="summaryItemContainer">
            <div className="summaryItemNumber">
                <p className="number">{papersPerGroup}</p>  
            </div>
        <p className="summaryItemLabel">Papers/group</p>
        </div>

        <div className="summaryItemContainer">
            <div className="summaryItemNumber">
                <p className="number">{papersThisYear}</p>  
            </div>
        <p className="summaryItemLabel">Papers this year</p>
        </div>
  
</div>
)
}
export default ThemeSidebarSummary