import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../store/actions/auth";
import "./auth.css"

const AuthLogout = () => {
  const [login, setLogin] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.logout());
  }, []);

  let pageDisplay;
  if (!login) {
    pageDisplay = (
      <div className="logoutContainer">
        <img src="LOGO.png" alt="logo" className="logo"/>
        <h3 className="logoutHeading1">
          Successfully logged out of the ONJCRI
        </h3>
        <h3 className="logoutHeading2">
          Performance & Impact Portal
        </h3>
        <p>To log back in click:</p>
        <button onClick={() => setLogin(true)} className="loginFormButton">LOGIN</button>
      </div>
    );
  } else {
    pageDisplay = <Redirect to="/login" />;
  }

  return pageDisplay;
};

export default AuthLogout;
