import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "./CreateResource.css"



const CreateResource = (props) => {


    const [file, setFile] = useState("")
    const [title, setTitle] = useState("")
    const [loading, setLoading] = useState("")
    const [uploaded, setUploaded] = useState(false)
    
    

    const handleSubmit = (filePath) => {
        const resource = {title, filePath}
        axios.post("/createResource",{resource})
        .then(response => {
          setUploaded(true)
          setLoading(false)
        })
        .catch(err => console.log("error"))
      };
    
    
    const handleFileSubmit = (evt) => {
        evt.preventDefault() 

        setLoading(true)
        let url="/addResourceFile"
      
        const formData = new FormData();
        formData.append('file',file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
          axios.post(url, formData, config)
          .then(response => handleSubmit(response.data.filePath))
    }
    
    
    

let pageDisplay

if (loading) {
    pageDisplay = (
      <div className="loaderContainer">
        <Loader
          type="ThreeDots"
          color="#2ca3da"
          height={80}
          width={80}
          className="loader"
        />
        <h1 className="loaderLabel">Uploading Data...</h1>
      </div>
    );
  } else if(!uploaded) {
    pageDisplay=
   <div className="uploadResourceContainer">
    <h1 className="uploadResourceHeader">Upload Resource</h1>
    <p className="uploadResourceInstructions">Select your file to upload then "Submit" when prompted</p>
    <div className="uploadResourceForm">
        <form onSubmit={handleFileSubmit} className="resourceForm">
                <label htmlFor="resourceTitle" className="resourceFormLabel">Resource Title</label>
                <input type="text" value={title} name="resourceTitle" onChange={(evt) => setTitle(evt.target.value)} className="resourceFileInput" placeholder="Enter a title for the file..."/>
                <label htmlFor="fileUpload" className="resourceFormLabel">Select file</label>
                <input required type="file" id="fileUpload" className="resourceUploadFileInput" name="photo" onChange={(evt) => setFile(evt.target.files[0])}/>
          
          {file ?
          <input type="submit" value="Submit" className="resourceFileSubmitButton"/>
          : null
          }
          </form>
    </div>
    </div> 
    } else if (uploaded){
        pageDisplay=
        <Redirect to={"/adminHome"}/>
    }
return pageDisplay
}

export default CreateResource