import React, { useState, useEffect } from "react";
import LabSelect from "../displayComponents/LabSelect";
import LabThemeSelectHeader from "../displayComponents/LabThemeSelectHeader";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import * as instActions from "../store/actions/institute";
import * as staffActions from "../store/actions/staff";
import "./AdminHome.css";

const AdminHome = () => {
  const staff = useSelector((state) => state.allStaff.staff);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const permissions = useSelector((state) => state.auth.permissions);
  // const token = useSelector((state) => state.auth.token);
  // const instStartYear = useSelector(
  //     (state) => state.institute.instituteStartYear
  //   );
  // const [staff, setStaff] = useState([]);
  const [createTheme, setCreateTheme] = useState(false);
  const [createInstitute, setCreateInstitute] = useState(false);
  const [createStaffMember, setCreateStaffMember] = useState(false);
  const [editStaffMember, setEditStaffMember] = useState(false);
  const [createAdmin, setCreateAdmin] = useState(false);
  const [createResource, setResource] = useState(false);
  const [selectedStaffMember, setSelectedStaffMember] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(false);
  const dispatch = useDispatch();

  const handleStaffSelect = (staffMember) => {
    setSelectedStaffMember(staffMember);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      alert(
        "Must be logged in to access this page - you will be redirected to login page."
      );
      setLoading(false);
      setAuthError(true);
    } else if (permissions === "admin") {
      dispatch(staffActions.getAllStaff())
        .then(() =>
          dispatch(
            instActions.getInstituteData(process.env.REACT_APP_INSTITUTE_NAME)
          )
        )
        .then(() => setLoading(false));
    } else if (permissions !== "admin") {
      alert(
        "You dont not have the correct permissions to access this page - you will be redirected to an entry point on the website where you have been granted acces or to the login page. If you believe you should have permissions for this page please contact your administrator."
      );
      setLoading(false);
      setAuthError(true);
    }
  }, []);

  let pageDisplay = "";
  if (loading) {
    pageDisplay = (
      <>
        <Loader
          type="ThreeDots"
          color="#2ca3da"
          height={80}
          width={80}
          className="adminHomeLoader"
        />
        <h1 className="adminHomeLoaderText">Loading Data...</h1>
      </>
    );
  } else if (authError) {
    pageDisplay = <Redirect push to={"/login"} />;
  } else if (selectedStaffMember) {
    pageDisplay = (
      <Redirect
        push
        to={{
          pathname: "/editStaffMember",
          state: { selectedStaffMember },
        }}
      />
    );
  } else if (createStaffMember) {
    pageDisplay = <Redirect push to={"/createStaffMember"} />;
  } else if (createAdmin) {
    pageDisplay = <Redirect push to={"/createAdmin"} />;
  } else if (createInstitute) {
    pageDisplay = <Redirect push to={"/createInstitute"} />;
  } else if (createTheme) {
    pageDisplay = <Redirect push to={"/createTheme"} />;
  } else if (createResource) {
    pageDisplay = <Redirect push to={"/createResource"} />;
  } else {
    pageDisplay = (
      <>
        <div className="labThemeSelectHeader">
          <LabThemeSelectHeader pageName="Administration Homepage" />
        </div>

        <div className="instituteDetailsContainer ">
          <h2 className="labThemeHeading">Institute Details</h2>
          <h4 className="labThemeSelectSubheading">
            Click on an item to enter or edit details
          </h4>

          <div className="bubbleList">
            <div className="bubble" onClick={() => setCreateStaffMember(true)}>
              <p className="bubbleDetail">New Staff</p>
              <p className="bubbleDetailLabel">Enter new staff details</p>
            </div>
            <div className="bubble" onClick={() => setCreateAdmin(true)}>
              <p className="bubbleDetail">Admin</p>
              <p className="bubbleDetailLabel">Enter new administrator</p>
            </div>
            <div className="bubble" onClick={() => setCreateInstitute(true)}>
              <p className="bubbleDetail">Institute</p>
              <p className="bubbleDetailLabel">Enter Institute Details</p>
            </div>
            <div className="bubble" onClick={() => setCreateTheme(true)}>
              <p className="bubbleDetail">Themes</p>
              <p className="bubbleDetailLabel">Enter new theme details</p>
            </div>
            <div className="bubble" onClick={() => setResource(true)}>
              <p className="bubbleDetail">Resources</p>
              <p className="bubbleDetailLabel">Add a resource</p>
            </div>
          </div>
        </div>

        <div className="labContainer">
          {Object.keys(staff).length ? (
            <>
              <h2 className="labThemeHeading">Researchers</h2>
              <h4 className="labThemeSelectSubheading">
                Click on a researcher edit data
              </h4>
              <LabSelect handleStaffSelect={handleStaffSelect} />
            </>
          ) : null}
        </div>
      </>
    );
  }

  return pageDisplay;
};

export default AdminHome;
