import './DisplayStaffPatents.css'


const DisplayStaffPatents = ({patents}) => {
    
    const current = patents.filter(patent =>patent.patentStatus !== "Lapsed")
    current.sort((a,b) => parseInt(a.patentPriorityYear) - parseInt(b.patentPriorityYear))
    const lapsed = patents.filter(patent =>patent.patentStatus === "Lapsed")
    lapsed.sort((a,b) => parseInt(a.patentPriorityYear) - parseInt(b.patentPriorityYear))
  
    let pageDisplay=

    <div className="patentsContainer">
    <p className="categorySubheading">Current</p>
    {current.length ?
    current.map((patent, idx) => {
        return (
            <p key={idx} className="categoryItem">{`${patent.patentPriorityYear}: "${patent.patentTitle}", Stage: ${patent.patentStage}, Commercialised: ${patent.patentCommercialised}`}</p>
            )
    })
    : 
    <p className="categoryItem">No current patents.</p>
    }
    
    <p className="categorySubheading">Lapsed</p>
    {lapsed.length ?
    lapsed.map((patent, idx) => {
      return (
        <p key={idx} className="categoryItem">{`${patent.patentPriorityYear}: "${patent.patentTitle}", Stage: ${patent.patentStage}, Commercialised: ${patent.patentCommercialised}`}</p>
        )
    })
    :  <p className="categoryItem">No lapsed patents.</p>
    }

    </div>
    
      return (
        pageDisplay
      );

};

export default DisplayStaffPatents