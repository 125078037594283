import React, {useState,useEffect } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import LabSelect from "./LabSelect"
import ThemeSelect from "./ThemeSelect"
// import Loader from "react-loader-spinner";
import LabThemeSelectHeader from "./LabThemeSelectHeader"
import { Redirect } from 'react-router-dom';
// import * as instActions from "../store/actions/institute";
import * as staffActions from "../store/actions/staff";
import * as authActions from '../store/actions/auth'
import "./LabThemeSelect.css"

const LabThemeSelect = () => {
    const staff = useSelector((state => state.allStaff.staff))
    // const instStartYear = useSelector(
    //     (state) => state.institute.instituteStartYear
    //   );
    const permissions = useSelector(state => state.auth.permissions)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const [authError, setAuthError] = useState(false)
    const [selectedStaffMember, setSelectedStaffMember] = useState("")
    const [selectedTheme, setSelectedTheme] = useState("")
    const [createReport, setCreateReport] = useState(false)
    // const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    
    const handleThemeSelect = (theme) => {
        setSelectedTheme(theme)
    }
    
    const handleStaffSelect = (staffMember) => {
    setSelectedStaffMember(staffMember)
    }

// I dont think this is necessary as the paper list updates after papers are loaded.

    // useEffect(() => {
    //   if(isLoggedIn && (permissions === "director" || permissions === "exec") ){             
    //   setLoading(true);
    //     dispatch(staffActions.getAllStaff())
    //       .then(() =>
    //         dispatch(
    //           instActions.getAllPaperData(
    //             process.env.REACT_APP_INSTITUTE_NAME,
    //             instStartYear
    //           )
    //         )
    //       )
    //       .then(() => setLoading(false))
    //     } else {
    //     dispatch(authActions.resetAuth())
    //     setAuthError(true)
    //   } 
  
    // }, []);  



    useEffect(() => {
        if(!isLoggedIn && (permissions !== "director" || permissions !== "exec")){             
          dispatch(authActions.resetAuth())
          setAuthError(true)
        }
      }, []);      
      
      
      useEffect(() => {
        if (selectedStaffMember) {
            dispatch(staffActions.selectedStaffMember(selectedStaffMember))
        }
      }, [selectedStaffMember])


let pageDisplay

// if (loading) {
//     pageDisplay = 
//       <>
//         <Loader type="ThreeDots" color="#2ca3da" height={80} width={80} className="pubsearchLoader"/>
//         <h1 className="loaderLabel">Fetching papers...</h1>
//       </>;
//   } else 
  if (!selectedTheme && !selectedStaffMember && !createReport && !authError){
            pageDisplay = 
            <>
            <div className="labThemeSelectHeader">
            <LabThemeSelectHeader
            pageName="Lab and Theme Data"/>
            </div>
            
            <div className="labThemeContainer">
            <div>
                <h3 className="labThemeSelectSubheading">Generate an Excel report on all researchers and institute stats</h3>
                </div>
                <button onClick={() => setCreateReport(true)} className="createReportButton">Create Report</button>
                
                <h3 className="labThemeSelectSubheading">Click on theme or researchers to display their details and data analysis</h3>
            <h2 className="labThemeHeading">Themes</h2>
            
            <ThemeSelect 
            handleThemeSelect={handleThemeSelect}
            />
            
            {Object.keys(staff).length ? 
            <>
            <h2 className="labThemeHeading">Researchers</h2>
            <LabSelect 
            handleStaffSelect={handleStaffSelect}
            />
            </>
            : null}
            </div>
            </>
          
        } else if (authError) {
          alert("Sorry you either have not logged in or do not have permission to access this page. You be redirected to the login page. If the error persists please contact your administrator as your permissions may need to be changed.")
          pageDisplay=
          <Redirect  
          to={"/login"}
          />
        } else if (selectedStaffMember) {
        pageDisplay = 
        <Redirect  
        push
        to={{
            pathname: "/displayStaffMember",
            state: { selectedStaffMember },
        }}
        />
        } else if (selectedTheme) {
            pageDisplay = 
            <Redirect  
            push
            to={{
            pathname: "/displayTheme",
            state: {selectedTheme}
            }}
            />
        } else if (createReport) {
            pageDisplay = 
            <Redirect  
            push
            to={{
            pathname: "/createReport",
            }}
            />
        }
    

return (
    pageDisplay
)
}

export default LabThemeSelect