import {Bar} from 'react-chartjs-2';
import "./InstitutePubsByYearChart.css"

const PubsByYear = ({pubsByYear}) =>{

        let years =[]
        let papersData = []
          pubsByYear.forEach(item => {
          years.push(item.year)
          papersData.push(item.papersAmt)
        }
        )
        

        const data = {
            labels: years,
            datasets: [
              {
                label: 'Publications Last 5 Years',
                backgroundColor: '#2ca3da',
                borderColor: 'rgb(48, 174, 239)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgb(147,202,69)',
                hoverBorderColor: 'rgb(70,182,86)',
                data: papersData
              }
            ]
          };

        return (
            <div className="chartContainer">
              <div className="chartHeading">Institute papers per year</div>
              <Bar
                data={data}
                options={{
                  maintainAspectRatio: false,
                  aspectRatio: 2,
                  barPercentage: 0.8,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />
              {/* <p className="barInstructions">Mouse over bar for stats</p> */}
            </div>
          );
    }

export default PubsByYear

