import * as xlsx from 'xlsx'

export const handleInternalUpload = (workBook) => {
  console.log("sheets")
    const ws = workBook.Sheets["Internal"]
    const data = xlsx.utils.sheet_to_json(ws)
    console.log("data" + JSON.stringify(data))
    let renameInternalData = data.map(item => {
      return {
      activity: item.Activity_Type,
      activityDescription: item.Activity_Description,
      activityStartYear: item.Start_Year,
      activityEndYear: item.End_Year,
      activityRole: item.Role
      }
    })
  return renameInternalData
  }

export const handleGrantsUpload = (workBook) => {
    const ws = workBook.Sheets["Grants"]
    const data = xlsx.utils.sheet_to_json(ws)
    const renameGrantData = data.map(item => {
      return {
      fundingBody: item.Funding_Body,
      grantStartYear: item.Start_Year,
      grantEndYear: item.End_Year,
      grantAmount: item.Amount,
      grantRole: item.Role,
      grantType: item.Type
      }
    })
    return renameGrantData
  }


export const handleStudentUpload = (workBook) => {
  const ws = workBook.Sheets["Students"]
  const data = xlsx.utils.sheet_to_json(ws)
  const renameStudentData = data.map(item => {
    return {
      studentName: item.Student_Name,
      studentType: item.Student_Type,
      studentStartYear: item.Start_Year,
      studentEndYear: item.End_Year,
      studentStatus:item.Status
    }
  })
  return renameStudentData
}

export const handleIndustryUpload = (workBook) => {
  const ws = workBook.Sheets["Industry"]
  const data = xlsx.utils.sheet_to_json(ws)
  const renameIndustryData = data.map(item => {
    return {
      company: item.Company,
      industryStartYear: item.Start_Year,
      industryEndYear: item.End_Year,
      mta: item.MTA,
      cra: item.CRA,
      industryFundingType: item.Funding_Type,
      industryAmount: item.Funding_Amount,
    }
  })
  return renameIndustryData
}

export const handleEngagementUpload = (workBook) => {
  console.log("workbook")
  const ws = workBook.Sheets["Engagement"]
  const data = xlsx.utils.sheet_to_json(ws)
  const renameEngagementData = data.map(item => {
    return {
      engagementYear: item.Year,
      engagementActivity: item.Activity,
      engagementType: item.Type,
      engagementEvent: item.Meeting_Event_Occasion,
    }
  })
  console.log("workbook" + JSON.stringify(renameEngagementData))
  return renameEngagementData
}



export const handlePatentUpload = (workBook) => {
  const ws = workBook.Sheets["Patents"]
  const data = xlsx.utils.sheet_to_json(ws)
  const renamePatentData = data.map(item => {
    return {
      patentPriorityYear: item.Priority_Date_Year,
      patentNumber: item.Patent_Number,
      patentTitle: item.Title,
      patentStage: item.Stage,
      patentStatus: item.Status,
      patentCommercialised: item.Commercialised,
    }
  })
  return renamePatentData
}

export const handleClinicalUpload = (workBook) => {
  const ws = workBook.Sheets["Clinical"]
  const data = xlsx.utils.sheet_to_json(ws)
  const renameClinicalData = data.map(item => {
    return {
      clinician: item.Clinician_Name,
      publications: item.Publications,
      trials: item.Trials,
      students: item.Students,
      grantApplications: item.Grant_Applications,
      samples: item.Samples,
    }
  })
  return renameClinicalData
}