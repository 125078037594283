import React, { useState } from "react";
import axios from "axios";
import YearDropDown from "./YearDropDown"
import YearDropDownCurrent from "./YearDropDownCurrent"
import "./CreateGrants.css";

const CreateGrants = (props) => {
  const staffMemberId = props.location.state.selectedStaffMember
  const [fundingBody, setFundingBody] = useState("");
  const [grantStartYear, setGrantStartYear] = useState("");
  const [grantEndYear, setGrantEndYear] = useState("");
  const [grantRole, setGrantRole] = useState("");
  const [grantAmount, setGrantAmount] = useState("");
  const [grantType, setGrantType] = useState("");
  const [grantCreated, setGrantCreated] = useState(false)
 

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const newGrant = {id: staffMemberId, fundingBody, grantType, grantStartYear, grantEndYear, grantRole, grantAmount}
    axios.patch("/addGrants",{newGrant})
    .then (response => alert(JSON.stringify(response.msg)))
    .then(setGrantCreated(true))
    .catch(err => console.log("error"))
  };

  let pageDisplay = ""
  if (!grantCreated) {
    pageDisplay=
  <div className="createGrantsContainer">
      <h1>Grants</h1>
      <form onSubmit={handleSubmit} className="form">
        <div className="labelFormGroup">
          <label htmlFor="fundingBody" className="formLabel">
            Funding Body
          </label>
          <input
            type="text"
            className="textInput"
            name="fundingBody"
            value={fundingBody}
            placeholder="Enter Funding Body"
            onChange={(evt) => setFundingBody(evt.target.value)}
          />
        </div>
        <div className="labelFormGroup">
          <label htmlFor="grantType" className="formLabel">
            Grant Type
          </label>
          <input
            type="text"
            className="textInput"
            name="grantType"
            value={grantType}
            placeholder="Enter Grant Type (e.g. Ideas, Discovery, Project etc)"
            onChange={(evt) => setGrantType(evt.target.value)}
          />
        </div>
        <div className="grantYears">
          <div className="labelFormGroup">
            <label htmlFor="grantStartYear" className="formLabel">
              Start Year
            </label>
            <select
              name="grantStartYear"
              value={grantStartYear}
              onChange={(evt) => setGrantStartYear(evt.target.value)}
              className="dropdown"
             
            >
              <YearDropDown />
            </select>
          </div>
          <div className="labelFormGroup">
            <label htmlFor="grantEndYear" className="formLabel">
              End Year
            </label>
            <select
              name="grantEndYear"
              value={grantEndYear}
              onChange={(evt) => setGrantEndYear(evt.target.value)}
              className="dropdown"
            >
              <YearDropDownCurrent />
            </select>
          </div>
         
          </div>
          <div className="labelFormGroup">
          <label htmlFor="grantAmount" className="formLabel">
            Amount Funded ($)
          </label>
          <input
            type="text"
            className="textInput"
            name="grantAmount"
            value={grantAmount}
            placeholder="Enter amound funded ($AU)"
            onChange={(evt) => setGrantAmount(evt.target.value)}
          />
        </div>
     
  
          <div className="labelFormGroup">
            <label htmlFor="grantRole" className="formLabel">
              Role
            </label>
            <select
              name="grantRole"
              value={grantRole}
              onChange={(evt) => setGrantRole(evt.target.value)}
              className="dropdown"
            >
              <option>Select Grant Role</option>
              <option>CIA</option>
              <option>PI</option>
              <option>Lead Investigator</option>
              <option>Co-PI</option>
              <option>CIB</option>
              <option>CIC</option>
              <option>CID</option>
              <option>CIE</option>
              <option>CIF</option>
              <option>CIG</option>
              <option>CIH</option>>
            </select>
          </div>

        <button className="submitButton" type="submit">
          Submit
        </button>
      </form>
    </div>
  } else {
    alert("Grant created")
  }


  return (
    pageDisplay
  );
};

export default CreateGrants;
