import {useSelector} from 'react-redux'
import "./LabThemeSelect.css"

const ThemeSelect = ({handleThemeSelect}) => {
    const themes = useSelector((state => state.institute.themes))
    themes.sort((a,b) => a.localeCompare(b))

    let pageDisplay = 

    <div className="themeSelectContainer"> 
        {themes.map(theme => {
        return(
        <div key={theme} className="themeType">
            <p onClick={()=> handleThemeSelect(theme)} className="themeLabel">{`${theme}`}</p>
        </div>
        )
    })
    }
    </div>
  



return (
    pageDisplay
)


}

export default ThemeSelect