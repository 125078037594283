import React, { useState, useEffect } from "react";
import axios from "axios";
import * as xlsx from 'xlsx'
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
// import * as instActions from "../store/actions/institute";
import * as staffActions from "../store/actions/staff";
import Loader from "react-loader-spinner";
import "./UploadAllData.css";
import {handleInternalUpload, handleGrantsUpload, handleStudentUpload, handleIndustryUpload, handleEngagementUpload, handlePatentUpload, handleClinicalUpload} from "../utilityComponents/uploadSpreadsheets"

const UploadAllData = (props) => {
    const [workBook, setWorkBook] = useState("")

  const [internalUploadData, setInternalUploadData] = useState([]);
  const [internalUploaded, setInternalUploaded] = useState(false);

  const [grantUploadData, setGrantUploadData] = useState([]);
  const [grantUploaded, setGrantUploaded] = useState(false);

  const [studentUploadData, setStudentUploadData] = useState([]);
  const [studentUploaded, setStudentUploaded] = useState(false);

  const [industryUploadData, setIndustryUploadData] = useState([]);
  const [industryUploaded, setIndustryUploaded] = useState(false);

  const [patentUploadData, setPatentUploadData] = useState([]);
  const [patentUploaded, setPatentUploaded] = useState(false);

  const [engagementUploadData, setEnagagementUploadData] = useState([]);
  const [engagementUploaded, setEnagagementUploaded] = useState(false);
  
  const [clinicalUploadData, setClinicalUploadData] = useState([]);
  const [clinicalUploaded, setClinicalUploaded] = useState(false);
    
    
    
    const [uploaded, setUploaded] = useState(false);
    const [reduxReset, setReduxReset] = useState(false);
    const [file, setFile] = useState("")
    const [loading, setLoading] = useState("")
    
    const staffMemberId = useSelector(state => state.allStaff.loginStaffMember)
    // const instStartYear = useSelector(
    //   (state) => state.institute.instituteStartYear
    // );
    
    const handleFileDataUpload = (evt) => {
        setFile(evt.target.files[0])
        const fileReader = new FileReader()
        fileReader.readAsArrayBuffer(evt.target.files[0])
        fileReader.onload = (e) => {
          const bufferArray = e.target.result
          const wb = xlsx.read(bufferArray, {type: "buffer"})
          setWorkBook(wb)
        }
    }


    const uploadSpreadsheets = (evt) => {
      evt.preventDefault()
      const setInternal = new Promise((resolve, reject) => {
        const internalData = handleInternalUpload(workBook)
        if(!internalData.length){
          setInternalUploaded(true)
        } else {
          setInternalUploadData(internalData)
        }
        resolve()
      })
      
      setInternal
      .then(() => {
      const grantsData = handleGrantsUpload(workBook)
      if(!grantsData.length){
        setGrantUploaded(true)
      } else {
        setGrantUploadData(grantsData)
      }
  
      const studentData  = handleStudentUpload(workBook)
      if(!studentData.length){
        setStudentUploaded(true)
      } else {
        setStudentUploadData(studentData)
      }
  
      const patentData = handlePatentUpload(workBook)
      if(!patentData.length) {
        setPatentUploaded(true)
      } else {
        setPatentUploadData(patentData)
      }
  
      const industryData = handleIndustryUpload(workBook)
      if (!industryData.length){
        setIndustryUploaded(true)
      } else {
        setIndustryUploadData(industryData)
      }
  
      const enagagementData = handleEngagementUpload(workBook)
      if(!enagagementData.length) {
        setEnagagementUploaded(enagagementData)
      } else {
        setEnagagementUploadData(enagagementData)
      }
  
      const clinicalData = handleClinicalUpload(workBook)
      if (!clinicalData.length){
        setClinicalUploaded(true)
      } else {
        setClinicalUploadData(clinicalData)
      }
      })
      // .then(() => {
      //   setUploaded(true)
      // })
      .catch(error => console.log("Upload error " + error))
    }


  //   const uploadSpreadsheets = (evt) => {
  //   evt.preventDefault()
  //   const setInternal = new Promise((resolve, reject) => {
  //     const internalData = handleInternalUpload(workBook)
  //     setInternalUploadData(internalData)
  //     resolve()
  //   })
    
  //   setInternal
  //   .then(() => {
  //   const grantsData = handleGrantsUpload(workBook)
  //   setGrantUploadData(grantsData)
  //   })
  //   .then(() => {
  //   const studentData  = handleStudentUpload(workBook)
  //   setStudentUploadData(studentData)
  //   })
  //   .then(() => {
  //     const patentData = handlePatentUpload(workBook)
  //     setPatentUploadData(patentData)
  //   })
  //   .then(() => {
  //     const industryData = handleIndustryUpload(workBook)
  //     setIndustryUploadData(industryData)
  //   })
  //   .then(() => {
  //     const enagagementData = handleEngagementUpload(workBook)
  //     setEnagagementUploadData(enagagementData)
  //   })
  //   .then(() => {
  //     const clinicalData = handleClinicalUpload(workBook)
  //     setClinicalUploadData(clinicalData)
  //   })
  //   .then(() => {
  //     setUploaded(true)
  //   })
  //   .catch(error => console.log("Upload error " + error))
  // }



      // const committeeData = await handleCommitteeUpload(workBook)
      // await setCommitteeUploadData(committeeData)
      // const grantsData = await handleGrantsUpload(workBook)
      // await setGrantUploadData(grantsData)
      // const studentData = await handleStudentUpload(workBook)
      // await setStudentUploadData(studentData)
      // const patentData = await handlePatentUpload(workBook)
      // await setPatentUploadData(patentData)
      // const industryData = await handleIndustryUpload(workBook)
      // await setIndustryUploadData(industryData)
      // const clinicalData = await handleClinicalUpload(workBook)
      // await setClinicalUploadData(clinicalData)
      // await setUploaded(true)
      //   }



// useEffect(() => {
//     if(internalUploadData.length){
//       console.log("internal"  + JSON.stringify(internalUploadData))
//           axios.patch("/addInternalUpload",{id: staffMemberId, internal:internalUploadData})
//     }
// }, [internalUploadData])

// useEffect(() => {
//     if(grantUploadData.length){
//           axios.patch("/addGrantsUpload",{id: staffMemberId, grants:grantUploadData})
//     }
// }, [grantUploadData])


// useEffect(() => {
//     if(studentUploadData.length){
//           axios.patch("/addStudentsUpload",{id: staffMemberId, students:studentUploadData})
//     }
// }, [studentUploadData])

// useEffect(() => {
//   if(patentUploadData.length){
//         axios.patch("/addPatentsUpload",{id: staffMemberId, patents:patentUploadData})
//   }
// }, [patentUploadData])

// useEffect(() => {
//   if(engagementUploadData.length){
//         axios.patch("/addEngagementUpload",{id: staffMemberId, engagement:engagementUploadData})           
//   }
// }, [engagementUploadData])

// useEffect(() => {
//     if(industryUploadData.length){
//           axios.patch("/addIndustryUpload",{id: staffMemberId, industry:industryUploadData})           
//     }
// }, [industryUploadData])

// useEffect(() => {
//   if(clinicalUploadData.length){
//         axios.patch("/addClinicalUpload",{id: staffMemberId, clinical:clinicalUploadData})   
//   }
// }, [clinicalUploadData])


const dispatch = useDispatch();

// useEffect(() => {
//   if (uploaded) {
//     alert("Data has been uploaded")
//     setLoading(true);
//     dispatch(staffActions.updateLoggedInUser(staffMemberId._id))
//       .then(() => setLoading(false))
//       .then(() => setReduxReset(true));
//   }
// }, [uploaded]);

useEffect(() => {
  if (internalUploadData.length) {
    axios.patch("/addInternalUpload", {
      id: staffMemberId,
      internal: internalUploadData,
    })
    .then(response => setInternalUploaded(true));
  }
}, [internalUploadData]);

useEffect(() => {
  if (grantUploadData.length) {
    axios.patch("/addGrantsUpload", {
      id: staffMemberId,
      grants: grantUploadData,
    })
    .then(response => setGrantUploaded(true));
  }
}, [grantUploadData]);

useEffect(() => {
  if (studentUploadData.length) {
    axios.patch("/addStudentsUpload", {
      id: staffMemberId,
      students: studentUploadData,
    })
    .then(response => setStudentUploaded(true));
  }
}, [studentUploadData]);

useEffect(() => {
  if (patentUploadData.length) {
    axios.patch("/addPatentsUpload", {
      id: staffMemberId,
      patents: patentUploadData,
    })
    .then(response => setPatentUploaded(true));
  }
}, [patentUploadData]);

useEffect(() => {
  if (industryUploadData.length) {
    axios.patch("/addIndustryUpload", {
      id: staffMemberId,
      industry: industryUploadData,
    })
    .then(response => setIndustryUploaded(true));
  }
}, [industryUploadData]);

useEffect(() => {
  if(engagementUploadData.length){
        axios.patch("/addEngagementUpload",{id: staffMemberId, engagement:engagementUploadData})
        .then(response => setEnagagementUploaded(true));           
  }
}, [engagementUploadData])

useEffect(() => {
  if (clinicalUploadData.length) {
    axios.patch("/addClinicalUpload", {
      id: staffMemberId,
      clinical: clinicalUploadData,
    })
    .then(response => setClinicalUploaded(true)); 
  }
}, [clinicalUploadData]);


useEffect(() => {
  if (internalUploaded && grantUploaded && studentUploaded && industryUploaded && patentUploaded && clinicalUploaded && engagementUploaded) {
    alert("Data has been uploaded")
    setLoading(true);
    dispatch(staffActions.updateLoggedInUser(staffMemberId._id))
      .then(() => setLoading(false))
      .then(() => setReduxReset(true));
  }
}, [internalUploaded, grantUploaded, studentUploaded, industryUploaded, patentUploaded, clinicalUploaded, engagementUploaded]);



// useEffect(() => {
//   if (uploaded ) {
//     setLoading(true);
//     dispatch(staffActions.getAllStaff())
//       .then(() =>
//         dispatch(
//           instActions.getInstituteData(process.env.REACT_APP_INSTITUTE_NAME)
//         )
//       )
//       .then(() =>
//         dispatch(
//           instActions.getAllPaperData(
//             process.env.REACT_APP_INSTITUTE_NAME,
//             instStartYear
//           )
//         )
//       )
//       .then(() => dispatch(staffActions.updateLoggedInUser(staffMemberId._id)))
//       .then(() => setLoading(false))
//       .then(() => setReduxReset(true))
//   }
// }, [uploaded]);

let pageDisplay

if (loading) {
    pageDisplay = (
      <div className="loaderContainer">
        <Loader
          type="ThreeDots"
          color="#2ca3da"
          height={80}
          width={80}
          className="loader"
        />
        <h1 className="loaderLabel">Uploading Data...</h1>
      </div>
    );
  } else if(!reduxReset) {
    pageDisplay=
   <div className="uploadDataContainer">
    <h1 className="uploadHeader">Upload Non-Publication Data</h1>
    <p className="uploadInstructions">Select your Excel file containing your data.</p>
    <p className="uploadInstructions">then "Upload" when prompted</p>
    <form className="uploadForm" onSubmit={uploadSpreadsheets} >
    <input type="file" onChange={handleFileDataUpload} className="fileInput"/>
    { file && 
    <input className="fileSubmitButton" type="submit" value="Upload"/>
    }
    </form>
    </div> 
    } else if (reduxReset){
        pageDisplay=
        <Redirect to={"/researcherPortal"}/>
    }
return pageDisplay
}

export default UploadAllData