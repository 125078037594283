const thisYear = new Date().getFullYear();

export const prepareInstituteData = (allInstituteData, reportList) => {
  const instituteData = [];
  instituteData.push([
    allInstituteData.allPubs,
    allInstituteData.pubsThisYear,
    allInstituteData.pubsLast7days.length,
    `${reportList.length} - see Reporting Period Papers tab for details`,
  ]);
  return instituteData;
};

export const prepareStaffData = (staffPubsSorted) => {
  const staffData = [];
  staffPubsSorted.forEach((staff) => {
    let papersThisYear;
    let papersThisYearFilter = staff.papersByYear.filter(
      (el) => parseInt(el.year) === parseInt(thisYear)
    );
    if (papersThisYearFilter.length) {
      papersThisYear = papersThisYearFilter[0].papers.length;
    } else {
      papersThisYear = 0;
    }
    let grantTotal = staff.grants.reduce(
      (acc, num) => acc + num.grantAmount,
      0
    );
    let grantTotalMill = (grantTotal / Math.pow(10, 6)).toFixed(2);

    staffData.push([
      `${staff.firstName} ${staff.lastName}`,
      staff.publications.length,
      papersThisYear,
      grantTotalMill,
      staff.students.length,
      staff.industry.length,
    ]);
  });
  return staffData;
};

export const prepareThemeData = (themeDetailsSorted, themesPubsByYear) => {
  const themeData = [];
  themeDetailsSorted.forEach((theme) => {
    let themePapersThisYear = 0;
    let themePapersFilter = themesPubsByYear.filter(
      (el) => el.theme === theme.theme
    );
    if (themePapersFilter.length) {
      themePapersFilter[0].papers.forEach((el) => {
        if (parseInt(el.year) === parseInt(thisYear)) {
          themePapersThisYear = el.yearPapers.length;
        }
      });

      themeData.push([
        theme.theme,
        theme.totalPapers.length,
        themePapersThisYear,
        (theme.totalPapers.length / theme.members.length).toFixed(1),
      ]);
    }
  });
  return themeData;
};

export const prepareReportPapers = (reportList) => {
  const reportPaperData = [];
  if (reportList.length) {
    reportList.forEach((paper) => {
      reportPaperData.push([
        paper.title,
        paper.journal,
        paper.authors,
        paper.id,
        paper.doi,
      ]);
    });
  } else {
    reportPaperData.push(["No papers in the reporting period."]);
  }
  return reportPaperData;
};
