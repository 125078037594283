import React, { useState, useEffect } from "react";
import axios from 'axios'
import {useSelector, useDispatch} from 'react-redux'
import { Redirect } from "react-router-dom";
import * as actions from '../store/actions/institute'
import "./CreateInstitute.css";

const Institute= () => {
  const [instituteName, setInstituteName] = useState("");
  const [instituteNameAbbreviation, setInstituteNameAbbreviation] = useState("");
  const [instituteStartYear, setInstituteStartYear] = useState("");
  const [getPapers, setGetPapers] = useState(false);
  const [instituteCreated, setInstituteCreated] = useState(false);
  const [instituteRefreshed, setInstituteRefreshed] = useState(false);
  const instituteLastFourYearPapers = useSelector(state => state.institute.pubsLastFour) || []
  const dispatch = useDispatch()

  const handlePapers = (evt) => {
    evt.preventDefault()
    setGetPapers(true)
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()
    const newInstitute = {instituteName, instituteNameAbbreviation, instituteStartYear, instituteLastFourYearPapers,themes: [] }

    axios.post("/createInstitute",{newInstitute})
    .then (setInstituteCreated(true))
    .then (alert("Institute Created"))
    .catch(err => console.log("error" + err))
  };

  useEffect(() => {
    if(getPapers){
    dispatch(actions.getLastFourYears(instituteName))
    setGetPapers(false)
    }
  }, [getPapers])


  useEffect(() => {
    if(instituteCreated){
    dispatch(actions.getInstituteData(instituteName))
    setInstituteRefreshed(true)
    }
  }, [instituteCreated])

let pageDisplay

if (!instituteRefreshed) {
  pageDisplay = 
  <div className="createInstituteContainer">
    <h1 className="createInstituteHeading">Create a New Institute</h1>
  
  <form onSubmit={handleSubmit}>
    <div className="formGroup">
      <div className="labelFormGroup">
        <label htmlFor="instituteName" className="formLabel">
          Institute Name
        </label>
        <input
          type="text"
          className="textInputInstitute"
          name="instituteName"
          value={instituteName}
          placeholder="Enter Institute Name"
          onChange={(evt) => setInstituteName(evt.target.value)}
        />
      </div>
      <div className="labelFormGroup">
        <label htmlFor="instituteNameAbbreviation" className="formLabel">
          Institute Name Abbreviation
        </label>
        <input
          type="text"
          className="textInputInstitute"
          name="instituteNameAbbreviation"
          value={instituteNameAbbreviation}
          placeholder="Enter Institute Name Abbreviation (e.g. WEHI etc)"
          onChange={(evt) => setInstituteNameAbbreviation(evt.target.value)}
        />
      </div>
      <div className="labelFormGroup">
        <label htmlFor="instituteStartYear" className="formLabel">
          Institute Start Year
        </label>
        <input
          type="text"
          className="textInputInstitute"
          name="instituteStartYear"
          value={instituteStartYear}
          placeholder="Enter Institute Start Year"
          onChange={(evt) => setInstituteStartYear(evt.target.value)}
        />
      </div>

      {(instituteName && instituteNameAbbreviation && instituteStartYear && !instituteLastFourYearPapers.length) ? 
      
        <button className="createInstituteButton" onClick={handlePapers}>Get Papers For Last 4 Years</button>
  
      : null
      }

      {(instituteName && instituteNameAbbreviation && instituteStartYear && instituteLastFourYearPapers.length) ?
      <input type="submit" value="Submit" className="createInstituteButton"/>
      : null
      }
    </div>

  </form>
  
</div>
} else {
  pageDisplay=
  <Redirect to={"/adminHome"}/>
}




  return (
   pageDisplay
  );
};

export default Institute;
