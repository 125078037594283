import "./DisplayStaffMemberHeader.css";
import dayjs from "dayjs";

const StaffMemberHeader = (props) => {
  const {
    firstName,
    lastName,
    photoPath,
    laboratory,
    program,
    position,
    dateStarted,
  } = props.staffDetails;
  
  return (
    <div className="staffMemberHeader">
      <img src={photoPath} className="staffPhoto" alt="staff member" />
      <div className="headerTextContainer">
        <h1 className="headerText">{`${firstName} ${lastName}`}</h1>
        {position === "Theme Leader" ? (
          <>
            <p className="headerText">{`${position}: ${program}`}</p>
            <p className="headerText">{`Head: ${laboratory}`}</p>
          </>
        ) : (
          <p className="headerText">{`${position}: ${laboratory}`}</p>
        )}
        <p className="headerText">{`Started: ${dayjs(dateStarted).format(
          "DD/MM/YYYY"
        )}`}</p>
      </div>
    </div>
  );
};

export default StaffMemberHeader;
