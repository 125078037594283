import axios from "axios";
import dayjs from 'dayjs'
import PaperList from "./PaperList";
export const CREATE_INSTITUTE = "CREATE_INSTITUTE";
export const ALL_INSTITUTE_PAPERS = "ALL_INSTITUTE_PAPERS";
export const LAST_FOUR_YEARS = "LAST_FIVE_YEARS";

export const getInstituteData = (instituteName) => {

  return async (dispatch) => {
    let instituteDetails = {};
    console.log("here")
    await axios
      .get(`/getInstitute/${instituteName}`)
      .then((response) => {
        console.log("instit" + JSON.stringify(response.data))
        instituteDetails = response.data.institute
      })
      .catch((error) => console.log("Error" + error));
      await dispatch({ type: CREATE_INSTITUTE, instituteDetails });
  };
};

export const getAllPaperData = (instituteName, instStartYear) => {
  
  const thisYear = new Date().getFullYear();
  const last7days = dayjs().subtract(7, "day").format("YYYY/MM/DD")

  const parametersEncodedAll = encodeURIComponent(
    `(((${instituteName}[Affiliation])) AND ("${instStartYear}/01/01"[Date - Publication] : "3000"[Date - Publication])`
    );

  const parametersEncodedThisYear = encodeURIComponent(
    `(${instituteName}[Affiliation]) AND (("${thisYear}/01/01"[Date - Publication] : "3000"[Date - Publication]))`
  );
  const parametersEncodedLast7days= encodeURIComponent(
    `(${instituteName}[Affiliation]) AND (("${last7days}"[Date - Publication] : "3000"[Date - Publication]))`
  );

  const urlAll = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedAll}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
  const urlThisYear = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedThisYear}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
  const urlLast7days = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedLast7days}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;

  const reqAll = axios.get(urlAll);
  const reqThisYear = axios.get(urlThisYear);
  const reqLast7days = axios.get(urlLast7days);

  let listAll,
    listThisYear,
    listLast7days = [];

  return async (dispatch) => {
    await axios
      .all([
        reqAll,
        reqThisYear,
        reqLast7days
      ])
      .then(
        axios.spread((...responses) => {
          listAll = [...responses[0].data.esearchresult.idlist];
          listThisYear = [...responses[1].data.esearchresult.idlist];
          listLast7days = [...responses[2].data.esearchresult.idlist];
        })
      )
      .catch((error) => console.log("Error" + error));


      let idlistString = listLast7days.toString()
      let paperList = []

      if (idlistString.length){
      await axios.get(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistString}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`)  
      .then(response => paperList = PaperList(response.data.result))
      .catch(error => console.log("Error" + error))
      }

    await dispatch({
      type: ALL_INSTITUTE_PAPERS,
      allPubs: listAll.length,
      pubsThisYear: listThisYear.length,
      pubsLast7days: paperList
    });
  };
};


export const getLastFourYears = (instituteName) => {
  const thisYear = new Date().getFullYear();
  const yearMinus4 = parseInt(thisYear) - 4;
  const yearMinus3 = parseInt(thisYear) - 3;
  const yearMinus2 = parseInt(thisYear) - 2;
  const yearMinus1 = parseInt(thisYear) - 1;
 
  const parametersEncodedMinus4 = encodeURIComponent(
    `(${instituteName}[Affiliation]) AND (("${yearMinus4}/01/01"[Date - Publication] : "${yearMinus4}/12/31"[Date - Publication]))`
  );
  const parametersEncodedMinus3 = encodeURIComponent(
    `(${instituteName}[Affiliation]) AND (("${yearMinus3}/01/01"[Date - Publication] : "${yearMinus3}/12/31"[Date - Publication]))`
  );
  const parametersEncodedMinus2 = encodeURIComponent(
    `(${instituteName}[Affiliation]) AND (("${yearMinus2}/01/01"[Date - Publication] : "${yearMinus2}/12/31"[Date - Publication]))`
  );
  const parametersEncodedMinus1 = encodeURIComponent(
    `(${instituteName}[Affiliation]) AND (("${yearMinus1}/01/01"[Date - Publication] : "${yearMinus1}/12/31"[Date - Publication]))`
  );
 

  const urlMinus4 = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedMinus4}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
  const urlMinus3 = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedMinus3}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
  const urlMinus2 = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedMinus2}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
  const urlMinus1 = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedMinus1}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;

  const reqMinus4 = axios.get(urlMinus4);
  const reqMinus3 = axios.get(urlMinus3);
  const reqMinus2 = axios.get(urlMinus2);
  const reqMinus1 = axios.get(urlMinus1);
 

  let listMinus4,
    listMinus3,
    listMinus2,
    listMinus1

  return async (dispatch) => {
    await axios
      .all([
        reqMinus4,
        reqMinus3,
        reqMinus2,
        reqMinus1,
      ])
      .then(
        axios.spread((...responses) => {
          listMinus4 = [...responses[0].data.esearchresult.idlist];
          listMinus3 = [...responses[1].data.esearchresult.idlist];
          listMinus2 = [...responses[2].data.esearchresult.idlist];
          listMinus1 = [...responses[3].data.esearchresult.idlist];
        })
      )
      .catch((error) => console.log("Error" + error));


    const pubsLastFour = [
      { year: yearMinus4, papersAmt: listMinus4.length},
      { year: yearMinus3, papersAmt: listMinus3.length},
      { year: yearMinus2, papersAmt: listMinus2.length},
      { year: yearMinus1, papersAmt: listMinus1.length},
    ];

    await dispatch({
      type: LAST_FOUR_YEARS,
      pubsLastFour,
    });
  };
};



// Unedited functions


// getAllPaperData Function

// export const getAllPaperData = (instituteName, instStartYear) => {
  
//   const thisYear = new Date().getFullYear();
//   const last7days = dayjs().subtract(7, "day").format("YYYY/MM/DD")
//   // const yearMinus5 = parseInt(thisYear) - 5;
//   const yearMinus4 = parseInt(thisYear) - 4;
//   const yearMinus3 = parseInt(thisYear) - 3;
//   const yearMinus2 = parseInt(thisYear) - 2;
//   const yearMinus1 = parseInt(thisYear) - 1;
 

//   const parametersEncodedAll = encodeURIComponent(
//     `(((${instituteName}[Affiliation])) AND ("${instStartYear}/01/01"[Date - Publication] : "3000"[Date - Publication])`
//     );
//   // const parametersEncodedMinus5 = encodeURIComponent(
//   //   `(${instituteName}[Affiliation]) AND (("${yearMinus5}/01/01"[Date - Publication] : "${yearMinus5}/12/31"[Date - Publication]))`
//   // );
  
//   const parametersEncodedMinus4 = encodeURIComponent(
//     `(${instituteName}[Affiliation]) AND (("${yearMinus4}/01/01"[Date - Publication] : "${yearMinus4}/12/31"[Date - Publication]))`
//   );
//   const parametersEncodedMinus3 = encodeURIComponent(
//     `(${instituteName}[Affiliation]) AND (("${yearMinus3}/01/01"[Date - Publication] : "${yearMinus3}/12/31"[Date - Publication]))`
//   );
//   const parametersEncodedMinus2 = encodeURIComponent(
//     `(${instituteName}[Affiliation]) AND (("${yearMinus2}/01/01"[Date - Publication] : "${yearMinus2}/12/31"[Date - Publication]))`
//   );
//   const parametersEncodedMinus1 = encodeURIComponent(
//     `(${instituteName}[Affiliation]) AND (("${yearMinus1}/01/01"[Date - Publication] : "${yearMinus1}/12/31"[Date - Publication]))`
//   );
//   const parametersEncodedThisYear = encodeURIComponent(
//     `(${instituteName}[Affiliation]) AND (("${thisYear}/01/01"[Date - Publication] : "3000"[Date - Publication]))`
//   );
//   const parametersEncodedLast7days= encodeURIComponent(
//     `(${instituteName}[Affiliation]) AND (("${last7days}"[Date - Publication] : "3000"[Date - Publication]))`
//   );

//   const urlAll = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedAll}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
//   // const urlMinus5 = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedMinus5}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
//   const urlMinus4 = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedMinus4}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
//   const urlMinus3 = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedMinus3}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
//   const urlMinus2 = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedMinus2}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
//   const urlMinus1 = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedMinus1}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
//   const urlThisYear = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedThisYear}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
//   const urlLast7days = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncodedLast7days}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;

//   const reqAll = axios.get(urlAll);
//   // const reqMinus5 = axios.get(urlMinus5);
//   const reqMinus4 = axios.get(urlMinus4);
//   const reqMinus3 = axios.get(urlMinus3);
//   const reqMinus2 = axios.get(urlMinus2);
//   const reqMinus1 = axios.get(urlMinus1);
//   const reqThisYear = axios.get(urlThisYear);
//   const reqLast7days = axios.get(urlLast7days);

//   let listAll,
//     // listMinus5,
//     listMinus4,
//     listMinus3,
//     listMinus2,
//     listMinus1,
//     listThisYear,
//     listLast7days = [];

//   return async (dispatch) => {
//     await axios
//       .all([
//         reqAll,
//         // reqMinus5,
//         reqMinus4,
//         reqMinus3,
//         reqMinus2,
//         reqMinus1,
//         reqThisYear,
//         reqLast7days
//       ])
//       .then(
//         axios.spread((...responses) => {
//           listAll = [...responses[0].data.esearchresult.idlist];
//           // listMinus5 = [...responses[1].data.esearchresult.idlist];
//           listMinus4 = [...responses[1].data.esearchresult.idlist];
//           listMinus3 = [...responses[2].data.esearchresult.idlist];
//           listMinus2 = [...responses[3].data.esearchresult.idlist];
//           listMinus1 = [...responses[4].data.esearchresult.idlist];
//           listThisYear = [...responses[5].data.esearchresult.idlist];
//           listLast7days = [...responses[6].data.esearchresult.idlist];
//         })
//       )
//       .catch((error) => console.log("Error" + error));


//       let idlistString = listLast7days.toString()
//       let paperList = []
  
//       await axios.get(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistString}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`)  
//       .then(response => paperList = PaperList(response.data.result))
//       .catch(error => console.log("Error" + error))



//     const pubsLastFive = [
//       // { year: yearMinus5, papersAmt: listMinus5.length},
//       { year: yearMinus4, papersAmt: listMinus4.length},
//       { year: yearMinus3, papersAmt: listMinus3.length},
//       { year: yearMinus2, papersAmt: listMinus2.length},
//       { year: yearMinus1, papersAmt: listMinus1.length},
//       { year: thisYear, papersAmt: listThisYear.length},
//       // { [yearMinus5]: listMinus5 },
//       // { [yearMinus4]: listMinus4 },
//       // { [yearMinus3]: listMinus3 },
//       // { [yearMinus2]: listMinus2 },
//       // { [yearMinus1]: listMinus1 },
//     ];

//     await dispatch({
//       type: ALL_INSTITUTE_PAPERS,
//       allPubs: listAll.length,
//       pubsThisYear: listThisYear.length,
//       pubsLastFive,
//       pubsLast7days: paperList
//     });
//   };
// };
