import './HomeHeader.css'

const HomeHeader= () => {
    return(
        <div>
            <div className="bannerContainer">
            <img src="./client/src/ONJCRI.jpg" alt="logo" className="bannerImage"/>
            <div className="bannerTitleContainer">
                <p className="bannerTitle">ONJCRI PERFORMANCE & IMPACT PORTAL</p>
            </div>
            </div>
        </div>
        
    )

}

export default HomeHeader