


export const calcStaffStats = (allStaff) => {

    let allStaffUpdated = []
    let themesByYear = []
    let staffByYear = []
    let themeDetails = []
     // let instituteByYear = []


    allStaff.forEach((member, idx) => {
        if(idx<1){
            themeDetails.push({theme: member.theme, totalPapers: [...member.pubIds], members: [{firstName:member.firstName, lastName:member.lastName, position: member.position, laboratory: member.laboratory}]})
        } else { 
        let themeTotalIndex = themeDetails.findIndex(item => item.theme === member.theme)
        if (themeTotalIndex >=0) {
            const arrayToJoin = [...member.pubIds]
            themeDetails[themeTotalIndex].totalPapers = themeDetails[themeTotalIndex].totalPapers.concat(arrayToJoin)
            themeDetails[themeTotalIndex].totalPapers = [...new Set(themeDetails[themeTotalIndex].totalPapers)]
            themeDetails[themeTotalIndex].members = themeDetails[themeTotalIndex].members.concat({firstName:member.firstName, lastName:member.lastName, position: member.position, laboratory: member.laboratory})

        } else {
            themeDetails.push({theme: member.theme, totalPapers: [...member.pubIds], members: [{firstName:member.firstName, lastName:member.lastName, position: member.position, laboratory: member.laboratory}]}) 
        }
        }
       
        const sortedPubs = member.publications.sort((a,b) => parseInt(b.pubdate)-parseInt(a.pubdate))
        
        sortedPubs.forEach((paper) => {
            
        if (idx > 0 || (idx <1 && themesByYear.length)){
                let yearIndex = staffByYear.findIndex(paperItem => paperItem.year === paper.pubdate)       
                if (yearIndex >= 0) { 
                staffByYear[yearIndex].papers.push(paper)
                } else {
                staffByYear.push({year: paper.pubdate, papers: [paper]})
                }
                
                let themeIndex = themesByYear.findIndex(item => item.theme === member.theme)
                if (themeIndex >= 0){
                   let themePaperIndex = themesByYear[themeIndex].papers.findIndex(item => item.year === paper.pubdate)

                   if (themePaperIndex >= 0) {
                       if (themesByYear[themeIndex].papers[themePaperIndex].yearPapers.findIndex(pap => pap.id === paper.id) < 0){
                       themesByYear[themeIndex].papers[themePaperIndex].yearPapers.push(paper)
                       }
                   } else {
                    themesByYear[themeIndex].papers.push({year: paper.pubdate, yearPapers: [paper]})
                    }
                } else {
                    themesByYear.push({theme: member.theme, papers: [{year: paper.pubdate, yearPapers: [paper]}]})
                }
            } else if (idx<1) { 
                staffByYear.push({year: paper.pubdate, papers: [paper]})
                
                if (!themesByYear.length) {
                themesByYear.push({theme: member.theme, papers: [{year: paper.pubdate, yearPapers: [paper]}]})
                } 
            }
        })

            member.papersByYear = staffByYear
            allStaffUpdated.push(member)
            staffByYear = []  
        }) 
  
  
    return (
    // {allStaffUpdated, instituteByYear, themesByYear}
    {allStaffUpdated, themesByYear, themeDetails}
    )
} 

            
            // if (!instituteByYear.length) {
            // instituteByYear.push({year:paper.pubdate, papers:[paper.id]})
            // } else {
            //     let instYearIndex = instituteByYear.findIndex(paperItem => paperItem.year === paper.pubdate)
            //     if (instYearIndex >= 0) {
            //     if (!instituteByYear[instYearIndex].papers.includes(paper.id)){
            //     instituteByYear[instYearIndex].papers.push(paper.id)
            //     }
            // } else {

            //     instituteByYear.push({year:paper.pubdate, papers:[paper.id]})
            // }
            // }