import { CREATE_PAPER_LIST, CLAIM_PAPER, REMOVE_PAPERS } from '../actions/papers'

const initialState = {
    paperList: [],
  };

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case CREATE_PAPER_LIST: 
            return {
                ...state,
                paperList: action.paperList
        };
        case CLAIM_PAPER: 
        const revPaperList = state.paperList
        const idx = revPaperList.findIndex(paper => paper.id === action.paperId)
        const objUpdated = {...revPaperList[idx]}
        objUpdated.claimed=true
        revPaperList[idx] = objUpdated
            return {
                ...state,
                paperList: revPaperList
        };
        case REMOVE_PAPERS: 
            return {
                ...state,
                paperList: []
        };
        default: return state
    }
}

export default reducer