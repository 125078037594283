import { REGISTER, LOGIN, RESET_AUTH, LOGOUT, ADMIN_REG } from '../actions/auth'
const initialState = { isLoggedIn: false, userId: "", token: null, isRegistered: false, permissions: "", adminReg: false}


const reducer = (state = initialState, action) => {
    switch (action.type) {
    case LOGIN: 
        return {...state, isLoggedIn: action.isLoggedIn, userId: action.userId, token: action.token, permissions: action.permissions}
    case REGISTER: 
        return {...state, isRegistered: action.registered, userId: action.userId}
    case RESET_AUTH: 
        return {...state, isRegistered: false, userId: "", isLoggedIn:false, permissions:"", token: null}
    case LOGOUT: 
        return {...state, isRegistered: false, userId: "", isLoggedIn:false, permissions:"", token: null}
    case ADMIN_REG: 
        return {...state, adminReg: action.adminReg}
    default: return state
    }
}

export default reducer

