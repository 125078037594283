import React, { useState } from 'react'
import {useSelector, useDispatch } from 'react-redux'
import * as allAuthActions from '../store/actions/auth'
import {Redirect} from 'react-router-dom'
import "./auth.css"


const AuthRegister = (props) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const registered = useSelector(state => state.auth.isRegistered)

    const dispatch = useDispatch()
    const hash = window.location.search.slice(1)
    const handleSubmit = (evt) => {
        evt.preventDefault()
        if(password !== password2) {
            alert("Passwords don't match. Please re-enter")
            return
        } else {
        const user = {email, password, hash}
        dispatch(allAuthActions.registerAdmin(user))
        }
    }


    
    let pageDisplay = ""
    if (!registered) {
    pageDisplay = 
    <form onSubmit={handleSubmit} className="authForm">
        <h4 className="formHeading">REGISTER NEW ADMINISTRATOR</h4>
        <div className="labelField">
            <label htmlFor="username" className="loginFormLabel">Email</label>
            <input type="email" onChange={(evt) => setEmail(evt.target.value.toLowerCase())} name="username" value={email} className="loginForm" placeholder="Enter email address"/>
        </div>
 
        <div className="labelField">
            <label htmlFor="password" className="loginFormLabel">Password</label>
            <input type="password" onChange={(evt) => setPassword(evt.target.value)} name="password" value={password} className="loginForm" placeholder="Enter password"/>
        </div>
        <div className="labelField">
            <label htmlFor="password" className="loginFormLabel">Password Repeat</label>
            <input type="password" onChange={(evt) => setPassword2(evt.target.value)} name="password2" value={password2} className="loginForm" placeholder="Enter password again"/>
        </div>

        <button type="submit" className="loginFormButton">Register</button>
    </form>
    
} else if (registered){
        alert("Registered successfully! You will now be redirected to login using this new password.")
        pageDisplay =
        <Redirect to="/login"
        /> 
    }


    return(
        <div className="authFormContainer">
        <img src="LOGO.png" alt="logo" className="logo"/>
        <h1 className="loginHeading1">ONJCRI</h1>
        <h1 className="loginHeading2">Performance & Impact Portal</h1>
            {pageDisplay}
        </div>
    )
}


export default AuthRegister