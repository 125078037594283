import {Bar} from 'react-chartjs-2';
import "./ThemePubsTotalChart.css"

const ThemePubsTotal = ({themeDetails, themeColor}) =>{
    const themeTotalsSorted = themeDetails.sort((a,b) => b.totalPapers.length-a.totalPapers.length)
    let themes =[]
    let papersData = []
   if (themeTotalsSorted.length) {
    
    themeTotalsSorted.forEach(item => {
    themes.push(item.theme)
    papersData.push(item.totalPapers.length)  
    })
  }

  let colorArray = new Array(themes.length).fill("#2ca3d")
  
  if (themeColor && themes.length) {
    const themeIndex = themes.findIndex(theme =>  theme === themeColor)
    colorArray.splice(themeIndex, 1, "#b96f7e")
  }

       const data = {
            labels: themes,
            datasets: [
              {
                label: 'Theme publications total',
                backgroundColor: colorArray,
                borderColor: 'rgb(48, 174, 239)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgb(147,202,69)',
                hoverBorderColor: 'rgb(70,182,86)',
                data: papersData
              }
            ]
          };

        return (
            <div className="chartContainer1">
              <div className="chartHeading">Theme papers total</div>
              <Bar
              className="graph"
                data={data}
                options={{
                  // indexAxis: "y",
                  maintainAspectRatio: false,
                  aspectRatio: 2,
                  barPercentage: 0.8,
                  plugins: {
                    legend: {
                      display: false
                    }
                  } 
                }}
              />
              {/* <p className="barInstructions">Mouse over bar for stats</p> */}
            </div>
          );
    }

export default ThemePubsTotal

