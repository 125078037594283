import { SELECTED_STAFF_MEMBER, GET_STAFF, UPDATE_LOGIN_STAFF_MEMBER } from '../actions/staff'
import { LOGIN_STAFF_MEMBER, RESET_LOGIN_STAFF_MEMBER, } from '../actions/auth'

const initialState = {
    staff: [],
    selectedStaffMember:"",
    loginStaffMember: ""
}


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_STAFF: {
            return {
                ...state,
                staff:action.allStaff
            }
        }
        case SELECTED_STAFF_MEMBER: {
            return {
                ...state,
                selectedStaffMember: action.staffMember
            }
        }
        case LOGIN_STAFF_MEMBER: {
            return {
                ...state,
                loginStaffMember: action.loginStaffMember
            }
        }
        case UPDATE_LOGIN_STAFF_MEMBER: {
            return {
                ...state,
                loginStaffMember: action.loginStaffMember
            }
        }
        case RESET_LOGIN_STAFF_MEMBER: {
            return {
                ...state,
                loginStaffMember: ""
            }
        }
        default: return state
    }
}

export default reducer