import axios from 'axios'
import dayjs from 'dayjs'
import PaperList from "./PaperList";


export const GetPapersLocal = async (searchName, date, nonONJ) => {

    const dateParams = dayjs(date).format("YYYY/MM/DD")
   
let parametersEncoded
   if(!nonONJ){
    parametersEncoded = encodeURIComponent(`((Olivia Newton-John Cancer Research Institute[Affiliation]) AND (${searchName}[Author])) AND ((${dateParams}[Date - Publication] : "3000"[Date - Publication]))`)
    } else {
        parametersEncoded = encodeURIComponent(`(${searchName}[Author]) AND ((${dateParams}[Date - Publication] : "3000"[Date - Publication]))`) 
    }
  
    // const parametersEncoded = encodeURIComponent(`(Olivia Newton-John Cancer Research Institute[Affiliation]`)
    

    const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncoded}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`

    
    let list = []
   
    const response = await axios.get(url)
    
    list = [...response.data.esearchresult.idlist]

    let idlistString = list.toString()
 
    if (!list.length) {
        return []
    } else {
        if(list.length > 300) {
            return "lengthError"
        } else {
    
        let paperList = []

    const response2 = await axios.get(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistString}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`)
    // .then(response => paperList = PaperList(response.data.result))  
    
    paperList = PaperList(response2.data.result)
 
    return paperList
    }
}
}