import React, { useState } from "react";
import axios from "axios";
import YearDropDown from "./YearDropDown"
import YearDropDownCurrent from "./YearDropDownCurrent"
import * as xlsx from 'xlsx'
import "./CreateCommittees.css";

const CreateCommittees = (props) => {
  const [committee, setCommittee] = useState("");
  const [committeeStartYear, setCommitteeStartYear] = useState("");
  const [committeeEndYear, setCommitteeEndYear] = useState("");
  const [committeePosition, setCommitteePosition] = useState("");
  const [committeeCreated, setCommitteeCreated] = useState(false)
  const [committeeUploadData, setCommitteeUploadData] = useState([]);
  
  const staffMemberId = props.location.state.selectedStaffMember
  //   const dispatch = useDispatch()

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const newCommittee = {id: staffMemberId, committee, committeeStartYear, committeeEndYear, committeePosition}
    axios.patch("/addCommittees",{newCommittee})
    .then (response => alert(JSON.stringify(response.msg)))
    .then(
        setCommitteeCreated(true)
    )
    .catch(err => console.log("error"))
  };

  const handleUpload = (evt) => {
    evt.preventDefault();
    axios.patch("/addCommitteesUpload",{id: staffMemberId, committees:committeeUploadData})
    .then (response => alert(JSON.stringify(response.msg)))
    .then(
        setCommitteeCreated(true)
    )
    .catch(err => console.log("error"))
  };



const handleAddAnother = (evt) => {
    evt.preventDefault()
    setCommittee("")
    setCommitteeStartYear("")
    setCommitteeEndYear("")
    setCommitteePosition("")
    setCommitteeCreated(false)
}

const handleFileDataUpload = (file) => {
  const fileReader = new FileReader()
  fileReader.readAsArrayBuffer(file)
  fileReader.onload = (e) => {
    const bufferArray = e.target.result
    const wb = xlsx.read(bufferArray, {type: "buffer"})
    const ws = wb.Sheets["Committees"]
    const data = xlsx.utils.sheet_to_json(ws)
    const renameData= data.map(item => {
      return {
      commitee: item.Committee,
      committeeStartYear: item.Start_Year,
      committeeEndYear: item.End_Year,
      committeePosition: item.Position
      }
    })
    setCommitteeUploadData(renameData)
  }
}

  let pageDisplay = ""
  if (!committeeCreated) {
    pageDisplay=
  
  <div className="createGrantsContainer">
      <h1>Committees</h1>
      
      <form onSubmit={handleUpload}>
      <input type="file" onChange={(evt) => {
        const file = evt.target.files[0]
        handleFileDataUpload(file)
      }}/>
      <input type="submit" value="Submit"/>
      </form>
      
      
      
      <form onSubmit={handleSubmit} className="form">
        <div className="labelFormGroup">
          <label htmlFor="committee" className="formLabel">
            Committee
          </label>
          <input
            type="text"
            className="textInput"
            name="committee"
            value={committee}
            placeholder="Enter committee"
            onChange={(evt) => setCommittee(evt.target.value)}
          />
        </div>
        <div className="committeeYears">
          <div className="labelFormGroup">
            <label htmlFor="committeeStartYear" className="formLabel">
              Committee Start Year
            </label>
            <select
              name="committeeStartYear"
              value={committeeStartYear}
              onChange={(evt) => setCommitteeStartYear(evt.target.value)}
              className="dropdown"
            >
              <YearDropDown />
            </select>
          </div>
         
          <div className="labelFormGroup">
            <label htmlFor="committeeEndYear" className="formLabel">
              Committee End Year
            </label>
            <select
              name="committeeStartYear"
              value={committeeEndYear}
              onChange={(evt) => setCommitteeEndYear(evt.target.value)}
              className="dropdown"
            >
              <YearDropDownCurrent />
            </select>
          </div>
          </div>
          <div className="labelFormGroup">
            <label htmlFor="committeePosition" className="formLabel">
             Committee Position
            </label>
            <select
              name="committeePosition"
              value={committeePosition}
              onChange={(evt) => setCommitteePosition(evt.target.value)}
              className="dropdown"
            >
              <option disabled>Select position</option>
              <option>Chair</option>
              <option>Member</option>
              <option>Other</option>
            </select>
          </div>
        <button className="submitButton" type="submit">
          Submit
        </button>
      </form>
    </div>
  } else {
    alert("Committee created");
    pageDisplay=
    <form onSubmit={handleAddAnother}>
    <input type="submit" value="Add Another"/>
    </form>
  }


  return (
    pageDisplay
  );
};

export default CreateCommittees;
