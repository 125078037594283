import {Bar} from 'react-chartjs-2';
import "./StaffPubsTotalChart.css"

const StaffPubsTotal = ({staffPubs, staffColor}) =>{
  const staffPubsSorted = staffPubs.sort((a,b) => b.publications.length - a.publications.length)
    let names =[]
    let papersData = []
   if (staffPubs.length) {
    
    staffPubsSorted.forEach(item => {
    names.push(item.firstName)
    papersData.push(item.publications.length) 
    })
  }
  
  let colorArray = new Array(names.length).fill("#2ca3d")
  if (staffColor && names.length) {
    const nameIndex = names.findIndex(name =>  name === staffColor)
    colorArray.splice(nameIndex, 1, "#b96f7e")
  }

       const data = {
            labels: names,
            datasets: [
              {
                label: 'Staff publications total',
                backgroundColor: colorArray,
                borderColor: 'rgb(48, 174, 239)',
                borderWidth: 2,
                hoverBackgroundColor: 'rgb(147,202,69)',
                hoverBorderColor: 'rgb(70,182,86)',
                data: papersData
              }
            ]
          };

        return (
            <div className="staffPapersChart">
              <div className="chartHeading">Staff papers totals</div>
              <Bar
                data={data}
                options={{
                  maintainAspectRatio: false,
                  aspectRatio: 2,
                  barPercentage: 0.8,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />
              {/* <p className="barInstructions">Mouse over bar for stats</p> */}
            </div>
          );
    }

export default StaffPubsTotal

