import { CREATE_INSTITUTE, ALL_INSTITUTE_PAPERS, LAST_FOUR_YEARS } from '../actions/institute'
import { INSTITUTE_PUBLICATIONS } from '../actions/staff'
import { CREATE_REPORT_LIST, RESET_REPORT } from '../actions/papers'

const initialState = {
    instituteName: "",
    instituteNameAbbreviation: "",
    instituteStartYear: "",
    themes: [],
    instituteID: "",
    instititePubsByYear: [],
    themesPubsByYear: [],
    allPubs: [],
    pubsThisYear: [],
    pubsLastFour: [],
    pubsLastFive: [],
    themeDetails: [],
    pubsLast7days: [],
    reportList: []
  };

const reducer = (state=initialState, action) => {
    switch (action.type) {  
        case CREATE_INSTITUTE: 
            return {
                ...state,
                themes: action.instituteDetails?.themes,
                instituteStartYear:action.instituteDetails?.instituteStartYear,
                instituteName:action.instituteDetails?.instituteName,
                instituteNameAbbreviation:action.instituteDetails?.instituteNameAbbreviation,
                instituteID:action.instituteDetails?._id,
                pubsLastFour: action.instituteDetails?.instituteLastFourYearPapers
        };
        case LAST_FOUR_YEARS: 
        return {
            ...state,
            pubsLastFour:action.pubsLastFour,
        };

        case INSTITUTE_PUBLICATIONS: 
            return {
                ...state,
                instititePubsByYear:action.institutePubsByYear,
                themesPubsByYear: action.themesPubsByYear,
                themeDetails: action.themeDetails
        };
        case ALL_INSTITUTE_PAPERS: 
            let thisYear = parseInt(new Date().getFullYear());
            let thisYearObj = {year: thisYear, papersAmt: action.pubsThisYear}
            let lastFive = [...state.pubsLastFour, thisYearObj]
     
            return {
                ...state,
                allPubs:action.allPubs,
                pubsThisYear:action.pubsThisYear,
                pubsLastFive:lastFive,
                pubsLast7days:action.pubsLast7days
        };
        case CREATE_REPORT_LIST: 
        return {
            ...state,
            reportList: action.paperList
    };
    case RESET_REPORT: 
    return {
        ...state,
        reportList: []
};
        default: return state
    }
}

export default reducer