import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Loader from "react-loader-spinner";
import * as allActions from "../store/actions/auth";
import "./auth.css";
import { Redirect } from "react-router-dom";

const AuthLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(true);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const permissions = useSelector((state) => state.auth.permissions);
  const token = useSelector((state) => state.auth.token);
  // const adminReg = useSelector((state) => state.auth.adminReg);
  // const admimCreate = useSelector((state) => state.auth.adminCreate);

  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const user = { email, password };
    if (!admin) {
      dispatch(allActions.login(user));
    } else {
      dispatch(allActions.loginAdmin(user));
    }
  };

  useEffect(() => {
    if (isLoggedIn && token) {
      axios
        .post("/tokenIsValid", null, { headers: { "x-auth-token": token } })
        .then((response) => {
          if (response.data) {
            setLoading(false);
          } else {
            alert(
              "Your previous session has expired - you will be redirected to the login page"
            );
            const result = async () => dispatch(allActions.resetAuth());
            result();
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }
  }, []);

  //  useEffect(() => {
  //   if(adminReg) {
  //     dispatch(allActions.registerAdministrator)
  //   }
  //  }, [adminReg])

  let pageDisplay = "";
  if (loading) {
    pageDisplay = (
      <div className="loaderContainer">
        <Loader
          type="ThreeDots"
          color="#2ca3da"
          height={80}
          width={80}
          className="loader"
        />
        <h1 className="loaderLabel">Loading Data...</h1>
      </div>
    );
  } else if (forgotPassword) {
    pageDisplay = <Redirect to="/forgotPasswordSendLink" />;
  } else if (!isLoggedIn) {
    pageDisplay = (
      <>
        <form onSubmit={handleSubmit} className="authForm">
          <h4 className="formHeading">LOGIN TO PORTAL</h4>
          <div className="labelField">
            <label htmlFor="username" className="loginFormLabel">
              Email
            </label>
            <input
              type="email"
              onChange={(evt) => setEmail(evt.target.value)}
              name="username"
              value={email}
              className="loginForm"
              placeholder="Enter email address"
            />
          </div>

          <div className="labelField">
            <label htmlFor="password" className="loginFormLabel">
              Password
            </label>
            <input
              type="password"
              onChange={(evt) => setPassword(evt.target.value)}
              name="password"
              value={password}
              className="loginForm"
              placeholder="Enter password"
            />
          </div>
          <div className="adminCheckContainer">
            <label htmlFor="adminCheck" className="adminCheckLabel">
              Administrator
            </label>
            <input
              type="checkbox"
              name="adminCheck"
              value={admin}
              checked={admin}
              onChange={() => setAdmin(!admin)}
            />
          </div>

          <button type="submit" className="loginFormButton">
            Login
          </button>
          <a
            href="https://pip.onjcri.org.au/forgotPasswordSendLink"
            className="forgotPasswordLink"
          >
            Forgot Password?
          </a>
        </form>
      </>
    );
  } else if (permissions === "director" || permissions === "exec") {
    pageDisplay = <Redirect to="/" />;
  } else if (permissions === "researcher") {
    pageDisplay = <Redirect to="/researcherPortal" />;
  } else if (permissions === "admin") {
    pageDisplay = <Redirect to="/adminHome" />;
  }

  return (
    <div className="authFormContainer">
      <img src="LOGO.png" alt="logo" className="logo" />
      <h3 className="loginHeading1">ONJCRI</h3>
      <h3 className="loginHeading2">Performance & Impact Portal</h3>
      <div></div>
      {pageDisplay}
    </div>
  );
};

export default AuthLogin;
