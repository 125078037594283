import axios from 'axios'
import dayjs from 'dayjs'
import PaperList from "./PaperList";
export const CREATE_PAPER_LIST = 'CREATE_PAPER_LIST'
export const CLAIM_PAPER = 'CLAIM_PAPER'
export const REMOVE_PAPERS = 'REMOVE_PAPERS'
export const RESET_REPORT = 'RESET_REPORT'
export const CREATE_REPORT_LIST = 'CREATE_REPORT_LIST'

export const getPapers = (searchName, date) => {
      const dateParams = dayjs(date).format("YYYY/MM/DD")
    const parametersEncoded = encodeURIComponent(`((Olivia Newton-John Cancer Research Institute[Affiliation]) AND (${searchName}[Author])) AND ((${dateParams}[Date - Publication] : "3000"[Date - Publication]))`)
  
    // const parametersEncoded = encodeURIComponent(`(Olivia Newton-John Cancer Research Institute[Affiliation]`)
    
    const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncoded}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`

    
    let list = []
    return async (dispatch) => {
   
    await axios.get(url)
    .then(response => {
    list = [...response.data.esearchresult.idlist]
    }
    )
    .catch(error => console.log("Error" + error)) 

    let idlistString = list.toString()
    let paperList = []

    await axios.get(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistString}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`)  
    .then(response => paperList = PaperList(response.data.result))
    .catch(error => console.log("Error" + error))
    
    await dispatch({type: CREATE_PAPER_LIST, paperList })
}
}

export const claimPaper = (paperId) => {
    return ({type: CLAIM_PAPER, paperId})
}

export const removePapers = () => {
    return ({type: REMOVE_PAPERS})
}

export const resetReport = () => {
    return ({type: RESET_REPORT})
}

export const getReportPapers = (startDate, endDate) => {

    
    const dateParam1 = dayjs(startDate).format("YYYY/MM/DD")
    const dateParam2 = dayjs(endDate).format("YYYY/MM/DD")



    const parametersEncoded = encodeURIComponent(`(Olivia Newton-John Cancer Research Institute[Affiliation]) AND ((${dateParam1}[Date - Publication] : ${dateParam2}[Date - Publication]))`)
  
    
    const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${parametersEncoded}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`

    
    let list = []
    return async (dispatch) => {
   
    await axios.get(url)
    .then(response => {
    list = [...response.data.esearchresult.idlist]
    }
    )
    .catch(error => console.log("Error" + error)) 

    let idlistString = list.toString()
    let paperList = []

    await axios.get(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistString}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`)  
    .then(response => paperList = PaperList(response.data.result))
    .catch(error => console.log("Error" + error))
    
    await dispatch({type: CREATE_REPORT_LIST, paperList })
}
}