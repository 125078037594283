import {Bar} from 'react-chartjs-2';
import "./ThemePubsThisYearChart.css"

const ThemePubsThisYear = ({themePubs, themeColor}) =>{
    const thisYear = new Date().getFullYear();
    
    let themes =[]
    let papersData = []
    let paperObjs = {}
   
    if (themePubs.length) { 

    themePubs.forEach(item => {
      let idx = item.papers.findIndex(el => parseInt(el.year) === parseInt(thisYear))
    if (idx < 0) {
        paperObjs[item.theme]= 0
    } else {
        paperObjs[item.theme] = item.papers[idx].yearPapers.length
    }
    })
   }
   
   const arrayObjs = Object.entries(paperObjs).sort((a,b) => b[1] - a[1])
   arrayObjs.forEach(item => {
    themes.push(item[0])
    papersData.push(item[1])
   })

   let colorArray = new Array(themes.length).fill("#2ca3d")
   if (themeColor && themes.length) {
    const themeIndex = themes.findIndex(theme =>  theme === themeColor)
    colorArray.splice(themeIndex, 1, "#b96f7e")
  }

       const data = {
            labels: themes,
            datasets: [
              {
                label: 'Papers this year by theme',
                backgroundColor: colorArray,
                borderColor: 'rgb(48, 174, 239)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgb(147,202,69)',
                hoverBorderColor: 'rgb(70,182,86)',
                data: papersData
              }
            ]
          };

        return (
            <div className="chartContainer2">
            <div className="chartHeading">Theme papers this year</div>
              <Bar
                data={data}
                options={{
                  // indexAxis: "y",
                  maintainAspectRatio: false,
                  aspectRatio: 2,
                  barPercentage: 0.8,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />
              {/* <p className="barInstructions">Mouse over bar for stats</p> */}
            </div>
          );
    }

export default ThemePubsThisYear

