import React, { useState} from "react";
import axios from "axios";
import YearDropDown from "./YearDropDown";
import YearDropDownCurrent from "./YearDropDownCurrent";
import "./CreateGrants.css";

const CreateStudents = (props) => {
  const [studentType, setStudentType] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentStartYear, setStudentStartYear] = useState("");
  const [studentEndYear, setStudentEndYear] = useState("");
  const [completed, setCompleted] = useState(false);
  const [studentCreated, setStudentCreated] = useState(false)
  const staffMemberId = props.location.state.selectedStaffMember


  //   const dispatch = useDispatch()

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const newStudent = {id: staffMemberId, studentName, studentType, studentStartYear, studentEndYear, completed}
    axios.patch("/addStudents",{newStudent})
    .then (response => alert(JSON.stringify(response.msg)))
    .then(setStudentCreated(true))
    .catch(err => console.log("error"))
  };


  let pageDisplay = ""
  if(!studentCreated) {
    pageDisplay =
    <div className="createStudentsContainer">
      <h1>Students</h1>
      <form onSubmit={handleSubmit} className="form">
      
      <div className="labelFormGroup">
          <label htmlFor="studentName" className="formLabel">
            Student Name
          </label>
          <input
            type="text"
            className="textInput"
            name="studentName"
            value={studentName}
            placeholder="Enter Student Name"
            onChange={(evt) => setStudentName(evt.target.value)}
          />
        </div>

        <div className="labelFormGroup">
          <label htmlFor="studentStartYear" className="formLabel">
            Start Year
          </label>
          <select
            name="studentStartYear"
            value={studentStartYear}
            onChange={(evt) => setStudentStartYear(evt.target.value)}
            className="dropdown"
          >
            <YearDropDown />
          </select>
        </div>

        <div className="labelFormGroup">
          <label htmlFor="studentEndYear" className="formLabel">
            End Year
          </label>
          <select
            name="studentEndYear"
            value={studentEndYear}
            onChange={(evt) => setStudentEndYear(evt.target.value)}
            className="dropdown"
          >
            <YearDropDownCurrent />
          </select>
        </div>
        
        <div className="labelFormGroup">
          <label htmlFor="studentType" className="formLabel">
            Degree
          </label>
          <select
            name="studentType"
            value={studentType}
            onChange={(evt) => setStudentType(evt.target.value)}
            className="dropdown"
          >
            <option>PhD</option>
            <option>Masters</option>
            <option>Honours</option>
          </select>
        </div>

        <div className="radioButtonGroup">
          <div>
            <label>
              <input
                type="radio"
                value="Completed"
                checked={completed}
                onChange={() => setCompleted(true)}
              />
              Completed
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                value="Current"
                checked={!completed}
                onChange={() => setCompleted(false)}
              />
              Current
            </label>
          </div>
        </div>

        <button className="submitButton" type="submit">
          Submit
        </button>
      </form>
    </div>
  } else {
    alert("student created")
  }

  return (
    pageDisplay
  );
};

export default CreateStudents;
