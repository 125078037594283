import { useSelector } from "react-redux";

import "./LabThemeSelect.css"



const LabSelect = ({handleStaffSelect}) => {
    const staff = useSelector((state => state.allStaff.staff))
    const sortedStaff = staff.sort((a,b) => a.firstName.localeCompare(b.firstName))
    const themeLeaders =  sortedStaff.filter(member => member.position==="Theme Leader")
    const labHeads =  sortedStaff.filter(member => member.position==="Laboratory Head")
    const groupLeaders =  sortedStaff.filter(member => member.position==="Group Leader")

    let pageDisplay = 
    <div className="labSelectContainer">
  
    {themeLeaders.length ?    
    <div className="researchersContainer">
     
        <h3 className="researcherTypeHeading">Theme Leaders</h3>
        <div className="researchers">
        {themeLeaders.map(member => {
        return(
        <div key={member._id} className="researcher">
            <img src={member.photoPath} alt="staff member" className="selectPhoto" onClick={()=> handleStaffSelect(member)}/>
            <p className="researcherName">{`${member.firstName}`}</p>
        </div>
        )
    })
    }
    </div>
    </div>
    : null
    }
     
    {labHeads.length ?   
       <div className="researchersContainer">
        <h3 className="researcherTypeHeading">Laboratory Heads</h3>
        <div className="researchers">
    {labHeads.map(member => {
        return(
        <div key={member._id}  className="researcher">
            <img src={member.photoPath} alt="staff member" className="selectPhoto" onClick={()=> handleStaffSelect(member)}/>
            <p className="researcherName">{`${member.firstName}`}</p>
        </div>
        )
    
    })}
    </div>
    </div>
    : null
    }
    
    {groupLeaders.length ?
    <div className="researchersContainer">
    <h3 className="researcherTypeHeading">Group Leaders</h3>  
    <div className="researchers">
      
     {groupLeaders.map(member => {
        return(
        <div key={member._id}  className="researcher">
            <img src={member.photoPath} alt="staff member" className="selectPhoto" onClick={()=> handleStaffSelect(member)}/>
            <p className="researcherName">{`${member.firstName}`}</p>
        </div>
        )})}
    </div>
    </div>
    
    : null
        }
    </div>
        

return (
    pageDisplay
)


}

export default LabSelect