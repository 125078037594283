import DisplayStaffPublications from "./DisplayStaffPublications"
import "./DisplayThemePublications.css"

const DisplayThemePublications = ({paperList}) => {

const pageDisplay=
<div className="themePaperListContainer">
{paperList.map(paper => <DisplayStaffPublications paperList={paper.yearPapers} displayDates={true} key={paper.year}/>)
}
</div>

return pageDisplay

}

export default DisplayThemePublications