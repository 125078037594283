const YearDropDown = () => {
  const thisYear = new Date().getFullYear();
  const startYear = 2014;
  const yearArray = [];
  for (let i = startYear; i <= thisYear + 2; i++) {
    yearArray.push(i);
  }

  const yearDisplay = yearArray.map((year) => (
    <option key={year} value={year}>{year}</option>
  ));

  return (
  <>
  <option disabled={true} value="">Select Year</option>
 { yearDisplay}
  </>  
  )
};

export default YearDropDown;

