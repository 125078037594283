import React, { useState, useEffect } from "react";
import axios from "axios";
import * as xlsx from "xlsx";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as staffActions from "../store/actions/staff";
import Loader from "react-loader-spinner";
import "./UploadAllData.css";
import {
  handleInternalUpload,
  handleGrantsUpload,
  handleStudentUpload,
  handleIndustryUpload,
  handlePatentUpload,
  handleClinicalUpload,
  handleEngagementUpload,
} from "../utilityComponents/uploadSpreadsheets";

const UpdateData = () => {
  const [workBook, setWorkBook] = useState("");
  const [internalUploadData, setInternalUploadData] = useState([]);
  const [internalUploaded, setInternalUploaded] = useState(false);

  const [grantUploadData, setGrantUploadData] = useState([]);
  const [grantUploaded, setGrantUploaded] = useState(false);

  const [studentUploadData, setStudentUploadData] = useState([]);
  const [studentUploaded, setStudentUploaded] = useState(false);

  const [industryUploadData, setIndustryUploadData] = useState([]);
  const [industryUploaded, setIndustryUploaded] = useState(false);

  const [patentUploadData, setPatentUploadData] = useState([]);
  const [patentUploaded, setPatentUploaded] = useState(false);

  const [engagementUploadData, setEnagagementUploadData] = useState([]);
  const [engagementUploaded, setEnagagementUploaded] = useState(false);
  
  const [clinicalUploadData, setClinicalUploadData] = useState([]);
  const [clinicalUploaded, setClinicalUploaded] = useState(false);

  const [reduxReset, setReduxReset] = useState(false);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState("");

  const staffMemberId = useSelector((state) => state.allStaff.loginStaffMember);

  const dispatch = useDispatch();

  const handleFileDataUpload = (evt) => {
    setFile(evt.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(evt.target.files[0]);
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = xlsx.read(bufferArray, { type: "buffer" });
      setWorkBook(wb);
    };
  };

  const handleDataDelete = async (evt) => {
    evt.preventDefault();
    await axios.post("/deleteAllNonPubData", { id: staffMemberId });
    uploadSpreadsheets();
  };

  const uploadSpreadsheets = () => {
    const setInternal = new Promise((resolve, reject) => {
      const internalData = handleInternalUpload(workBook)
      if(!internalData.length){
        setInternalUploaded(true)
      } else {
        setInternalUploadData(internalData)
      }
      resolve()
    })
    
    setInternal
    .then(() => {
    const grantsData = handleGrantsUpload(workBook)
    if(!grantsData.length){
      setGrantUploaded(true)
    } else {
      setGrantUploadData(grantsData)
    }

    const studentData  = handleStudentUpload(workBook)
    if(!studentData.length){
      setStudentUploaded(true)
    } else {
      setStudentUploadData(studentData)
    }

    const patentData = handlePatentUpload(workBook)
    if(!patentData.length) {
      setPatentUploaded(true)
    } else {
      setPatentUploadData(patentData)
    }

    const industryData = handleIndustryUpload(workBook)
    if (!industryData.length){
      setIndustryUploaded(true)
    } else {
      setIndustryUploadData(industryData)
    }

    const enagagementData = handleEngagementUpload(workBook)
    if(!enagagementData.length) {
      setEnagagementUploaded(enagagementData)
    } else {
      setEnagagementUploadData(enagagementData)
    }

    const clinicalData = handleClinicalUpload(workBook)
    if (!clinicalData.length){
      setClinicalUploaded(true)
    } else {
      setClinicalUploadData(clinicalData)
    }
    })
    .catch(error => console.log("Upload error " + error))
  }

  useEffect(() => {
    if (internalUploadData.length) {
      axios.patch("/addInternalUpload", {
        id: staffMemberId,
        internal: internalUploadData,
      })
      .then(response => setInternalUploaded(true));
    }
  }, [internalUploadData]);

  useEffect(() => {
    if (grantUploadData.length) {
      axios.patch("/addGrantsUpload", {
        id: staffMemberId,
        grants: grantUploadData,
      })
      .then(response => setGrantUploaded(true));
    }
  }, [grantUploadData]);

  useEffect(() => {
    if (studentUploadData.length) {
      axios.patch("/addStudentsUpload", {
        id: staffMemberId,
        students: studentUploadData,
      })
      .then(response => setStudentUploaded(true));
    }
  }, [studentUploadData]);

  useEffect(() => {
    if (patentUploadData.length) {
      axios.patch("/addPatentsUpload", {
        id: staffMemberId,
        patents: patentUploadData,
      })
      .then(response => setPatentUploaded(true));
    }
  }, [patentUploadData]);

  useEffect(() => {
    if (industryUploadData.length) {
      axios.patch("/addIndustryUpload", {
        id: staffMemberId,
        industry: industryUploadData,
      })
      .then(response => setIndustryUploaded(true));
    }
  }, [industryUploadData]);

  useEffect(() => {
    if(engagementUploadData.length){
          axios.patch("/addEngagementUpload",{id: staffMemberId, engagement:engagementUploadData})
          .then(response => setEnagagementUploaded(true));           
    }
  }, [engagementUploadData])
  
  useEffect(() => {
    if (clinicalUploadData.length) {
      axios.patch("/addClinicalUpload", {
        id: staffMemberId,
        clinical: clinicalUploadData,
      })
      .then(response => setClinicalUploaded(true)); 
    }
  }, [clinicalUploadData]);


  useEffect(() => {
    if (internalUploaded && grantUploaded && studentUploaded && industryUploaded && patentUploaded && clinicalUploaded && engagementUploaded) {
      alert("Data has been uploaded")
      setLoading(true);
      dispatch(staffActions.updateLoggedInUser(staffMemberId._id))
        .then(() => setLoading(false))
        .then(() => setReduxReset(true));
    }
  }, [internalUploaded, grantUploaded, studentUploaded, industryUploaded, patentUploaded, clinicalUploaded, engagementUploaded]);



  // useEffect(() => {
  //   if (uploaded) {
  //     alert("Data has been uploaded")
  //     setLoading(true);
  //     dispatch(staffActions.updateLoggedInUser(staffMemberId._id))
  //       .then(() => setLoading(false))
  //       .then(() => setReduxReset(true));
  //   }
  // }, [uploaded]);

  let pageDisplay;

  if (loading) {
    pageDisplay = (
      <div className="loaderContainer">
        <Loader
          type="ThreeDots"
          color="#2ca3da"
          height={80}
          width={80}
          className="loader"
        />
        <h1 className="loaderLabel">Updating Data...</h1>
      </div>
    );
  } else if (!reduxReset) {
    pageDisplay = (
      <div className="uploadDataContainer">
        <h1 className="uploadHeader">Update Data</h1>
        <p className="uploadInstructions">
          Update/edit your data in the Excel file used to create your portal.
        </p>
        <p className="uploadInstructions">Then Select and Upload your file...</p>

        <form className="uploadForm" onSubmit={handleDataDelete}>
          <input
            type="file"
            onChange={handleFileDataUpload}
            className="fileInput"
          />
          {file && (
            <input className="fileSubmitButton" type="submit" value="Upload" />
          )}
        </form>
      </div>
    );
  } else if (reduxReset) {
    pageDisplay = <Redirect to={"/researcherPortal"} />;
  }
  return pageDisplay;
};

export default UpdateData;
