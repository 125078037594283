import "./ReportingDropdown.css";

const ReportingDropdown = ({ handleTimeFrame }) => {
  const timeFrame = "";

  return (
    <div className="dateInputContainer">
      <div className="dateInputLabelContainer">
        <label htmlFor="periodDropDown" className="dateInputLabel">
          Time Frame
        </label>
        <select
          onChange={handleTimeFrame}
          defaultValue={timeFrame}
          className="timeFrameDropdown"
        >
          <option value="" disabled>
            Select a timeframe to collect papers...
          </option>
          <option value={7}>Last 7 days</option>
          <option value={14}>Last 14 days</option>
          <option value={30}>Last 1 month</option>
          <option value={60}>Last 2 months</option>
          <option value={90}>Last 3 months</option>
          <option value={120}>Last 4 months</option>
          <option value={180}>Last 6 months</option>
          <option value={365}>Last year</option>
        </select>
      </div>
    </div>
  );
};

export default ReportingDropdown;
