import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import * as instActions from "../store/actions/institute";
import * as staffActions from "../store/actions/staff";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
// import * as actions from './store/actions/staff'
import "./CreateStaffMember.css";

const CreateStaffMember = () => {
  const [gender, setGender] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [theme, setTheme] = useState("");
  const [laboratory, setLaboratory] = useState("");
  const [dateStarted, setDateStarted] = useState("");
  const [permissions, setPermissions] = useState("");
  const [file, setFile] = useState("");
  const [created, setCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const instStartYear = useSelector(
    (state) => state.institute.instituteStartYear
  );
  const themes = useSelector((state) => state.institute.themes);
  // const [photoPath, setPhotoPath] = useState("")

  const dispatch = useDispatch();

  const sendEmail = (email, hash) => {
    axios
      .post("/sendEmail", { email, hash })
      .then((response) => {})
      .catch((err) => console.log("error"));
  };

  const handleSubmit = (photoPath) => {
    const staffMember = {
      firstName,
      lastName,
      gender,
      email,
      position,
      theme,
      laboratory,
      dateStarted,
      photoPath,
      permissions,
    };
    axios
      .post("/createStaffMember", { staffMember })
      .then((response) => {
        sendEmail(response.data.staffMember.email, response.data.hash);
      })
      .then(setCreated(true), setLoading(false))
      .catch((err) => console.log("error"));
  };

  const handlePhotoSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);
    let url = "/addStaffPhoto";

    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(url, formData, config)
      // .then(response => console.log("sdfkdjks " + JSON.stringify(response)))
      // .then(response => setPhotoPath(response.data))
      .then((response) => handleSubmit(response.data.photoPath));
  };

  useEffect(() => {
    if (created) {
      setLoading(true);
      dispatch(staffActions.getAllStaff())
        .then(() =>
          dispatch(
            instActions.getAllPaperData(
              process.env.REACT_APP_INSTITUTE_NAME,
              instStartYear
            )
          )
        )
        .then(() => setLoading(false));
    }
  }, [created]);

  let pageDisplay;
  if (loading) {
    pageDisplay = (
      <div className="loaderContainer">
        <Loader
          type="ThreeDots"
          color="#2ca3da"
          height={80}
          width={80}
          className="loader"
        />
        <h1 className="loaderLabel">Loading Data...</h1>
      </div>
    );
  } else if (!created) {
    pageDisplay = (
      <div className="createStaffContainer">
        <h1 className="createStaffHeading">Create a new staff member</h1>
        <p className="createStaffSubHeading">
          Enter all details and upload photo for a new staff member
        </p>
        <form onSubmit={handlePhotoSubmit}>
          <div className="labelFormGroup">
            <label htmlFor="firstName" className="formLabel">
              First Name
            </label>
            <input
              required
              type="text"
              className="textInputStaff"
              name="firstName"
              value={firstName}
              placeholder="Enter First Name"
              onChange={(evt) => setFirstName(evt.target.value)}
            />
          </div>
          <div className="labelFormGroup">
            <label htmlFor="lastName" className="formLabel">
              Last Name
            </label>
            <input
              required
              type="text"
              className="textInputStaff"
              name="lastName"
              value={lastName}
              placeholder="Enter Last Name"
              onChange={(evt) => setLastName(evt.target.value)}
            />
          </div>

          <div className="labelFormGroup">
            <label htmlFor="firstName" className="formLabel">
              Email
            </label>
            <input
              required
              type="email"
              className="textInputStaff"
              name="email"
              value={email}
              placeholder="Enter email address"
              onChange={(evt) => setEmail(evt.target.value.toLowerCase())}
            />
          </div>

          <div className="labelFormGroup">
            <label htmlFor="dateStarted" className="formLabel">
              Date Started
            </label>
            <input
              required
              type="date"
              name="dateStarted"
              value={dateStarted}
              onChange={(evt) => setDateStarted(evt.target.value)}
              className="dateInputStaff"
            />
          </div>

          <div className="labelFormGroup">
            <label htmlFor="gender" className="formLabel">
              Gender
            </label>
            <select
              required
              name="gender"
              value={gender}
              onChange={(evt) => setGender(evt.target.value)}
              className="dropdownStaff"
            >
              <option diabled>Select gender...</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="labelFormGroup">
            <label htmlFor="position" className="formLabel">
              Position
            </label>
            <select
              required
              name="position"
              value={position}
              onChange={(evt) => setPosition(evt.target.value)}
              className="dropdownStaff"
            >
              <option diabled>Select position</option>
              <option>Theme Leader</option>
              <option>Laboratory Head</option>
              <option>Group Leader</option>
            </select>
          </div>

          <div className="formGroup">
            <div className="labelFormGroup">
              <label htmlFor="theme" className="formLabel">
                Theme
              </label>
              <select
                required
                name="theme"
                value={theme}
                onChange={(evt) => setTheme(evt.target.value)}
                className="dropdownStaff"
              >
                <option diabled>Select theme...</option>
                {themes.map((theme) => (
                  <option>{theme}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="labelFormGroup">
            <label htmlFor="laboratory" className="formLabel">
              Laboratory
            </label>
            <input
              required
              type="text"
              name="laboratory"
              placeholder="Enter labortaory name"
              value={laboratory}
              onChange={(evt) => setLaboratory(evt.target.value)}
              className="textInputStaff"
            />
          </div>

          <div className="formGroup">
            <div className="labelFormGroup">
              <label htmlFor="permissions" className="formLabel">
                Permissions
              </label>
              <select
                required
                name="permissions"
                value={permissions}
                onChange={(evt) => setPermissions(evt.target.value)}
                className="dropdownStaff"
              >
                <option diabled>Select permissions...</option>
                <option>researcher</option>
                <option>admin</option>
                <option>exec</option>
                <option>director</option>
              </select>
            </div>
          </div>

          <div className="uploadForm">
            <label htmlFor="photoUpload" className="formLabel">
              Select a photo to upload this staff member
            </label>
            <input
              required
              type="file"
              id="photoUpload"
              className="uploadFileInput"
              name="photo"
              onChange={(evt) => setFile(evt.target.files[0])}
            />

            {firstName &&
            lastName &&
            gender &&
            email &&
            position &&
            theme &&
            laboratory &&
            dateStarted &&
            file ? (
              <input
                type="submit"
                value="Submit"
                className="createStaffSubmit"
              />
            ) : null}
          </div>
        </form>
      </div>
    );
  } else if (created) {
    pageDisplay = <Redirect push to="/adminHome" />;
  }

  return pageDisplay;
};

export default CreateStaffMember;
