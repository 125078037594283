import './DisplayStaffClinical.css'


const DisplayStaffClinical = ({clinical}) => {
  
    let pageDisplay=
    <div className="clinicalContainer">
    {clinical.map((clin, idx) => {
        return (
            <p key={idx} className="categoryItem">{`${clin.clinician}:`} {clin.publications === "Yes" && "Publications /"} {clin.students === "Yes" && "Students /"} {clin.grantApplications === "Yes" && "Grant Applications /"} {clin.samples === "Yes" && "Samples"}</p>
        )
    })
    } 
   </div>
    
      return (
        pageDisplay
      );

};

export default DisplayStaffClinical