import React, { useState } from "react";
import axios from "axios";
import YearDropDown from "./YearDropDown";
import "./CreateIndustry.css";

const CreateIndustry = (props) => {
  const [company, setCompany] = useState("");
  const [industryStartYear, setIndustryStartYear] = useState("");
  const [funding, setFunding] = useState();
  const [mta, setMTA] = useState(false);
  const [cra, setCRA] = useState(false);
  const [industryFundingType, setIndustryFundingType] = useState(false);
  const [industryAmount, setIndustryAmount] = useState("");
  const [industryCreated, setIndustryCreated] = useState(false);
  const staffMemberId = props.location.state.selectedStaffMember;

  //   const dispatch = useDispatch()

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const newIndustry = {
      id: staffMemberId,
      company,
      industryStartYear,
      funding,
      mta,
      cra,
      industryFundingType,
      industryAmount,
    };
    axios
      .patch("/addIndustry", { newIndustry })
      .then((response) => alert(JSON.stringify(response.msg)))
      .then(setIndustryCreated(true))
      .catch((err) => console.log("error"));
  };

  let pageDisplay = "";
  if (!industryCreated) {
    pageDisplay = (
      <div className="createGrantsContainer">
        <h1>Industry Collaboration</h1>
        <form onSubmit={handleSubmit} className="form">
          <div className="labelFormGroup">
            <label htmlFor="company" className="formLabel">
              Company
            </label>
            <input
              type="text"
              className="textInput"
              name="company"
              value={company}
              placeholder="Company"
              onChange={(evt) => setCompany(evt.target.value)}
            />
          </div>
          <div className="labelFormGroup">
            <label htmlFor="industryStartYear" className="formLabel">
              Start Year
            </label>
            <select
              name="industryStartYear"
              value={industryStartYear}
              onChange={(evt) => setIndustryStartYear(evt.target.value)}
              className="dropdown"
            >
              <YearDropDown />
            </select>
          </div>

          <div className="checkboxes">
            <p>Agreements / Funding</p>
            <div className="checkboxGroup">
              <div>
                <label>
                  <input
                    type="checkbox"
                    value={mta}
                    checked={mta}
                    onChange={() => setMTA(true)}
                  />
                  MTA
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    value={cra}
                    checked={cra}
                    onChange={() => setCRA(true)}
                  />
                  CRA
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    value={funding}
                    checked={funding}
                    onChange={() => setFunding(true)}
                  />
                  Funded
                </label>
              </div>
            </div>
          </div>

          {funding ? (
            <div className="fundingDetails">
              <p>Funding Details</p>
              <div className="checkInputGroup">
                <div className="checkboxes">
                  <div className="checkboxGroup">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value={industryFundingType}
                          checked={industryFundingType === "direct"}
                          onChange={() => setIndustryFundingType("direct")}
                        />
                        Direct
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value={industryFundingType}
                          checked={industryFundingType === "grant"}
                          onChange={() => setIndustryFundingType("grant")}
                        />
                        Grant
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value={industryFundingType}
                          checked={industryFundingType === "grant + direct"}
                          onChange={() => setIndustryFundingType("grant + direct")}
                        />
                        Grant + Direct
                      </label>
                    </div>
                  </div>
                </div>
                <div className="labelFormGroup">
                  {/* <label htmlFor="industryAmount" className="formLabel">
      Funding Amount
    </label> */}
                  <input
                    type="text"
                    className="industryAmountInput"
                    name="industryAmount"
                    value={industryAmount}
                    placeholder="Funding Amount"
                    onChange={(evt) => setIndustryAmount(evt.target.value)}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <button className="submitButton" type="submit">
            Submit
          </button>
        </form>
      </div>
    );
  } else {
    alert("Industry created");
  }

  return pageDisplay;
};

export default CreateIndustry;
