import React, {useState} from 'react'
import "./DisplayTheme.css";
import DisplayThemeHeader from "./DisplayThemeHeader";
import DisplayThemePublications from "./DisplayThemePublications";
import ThemePubsTotalChart from "../chartComponents/ThemePubsTotalChart";
import ThemePubsThisYearChart from "../chartComponents/ThemePubsThisYearChart";
import ThemePubsPerMemberChart from "../chartComponents/ThemePubsPerMemberChart";
import ThemePubsByYearChart from "../chartComponents/ThemePubsByYearChart";
import ThemeSidebarSummary from "./ThemeSidebarSummary";
import { useSelector } from "react-redux";
import e from 'cors';

const DisplayTheme = (props) => {
  const [displayData, setDisplayData] = useState(false)
  const thisYear = new Date().getFullYear();
  const theme = props.location.state.selectedTheme;
  const allDetails = useSelector((state) => state.institute.themeDetails);
  const themePubsByYear = useSelector(
    (state) => state.institute.themesPubsByYear
  );
  const themeDetails = allDetails.filter((item) => item.theme === theme);
  const selectedThemePubs = themePubsByYear.filter(
    (item) => item.theme === theme
  );

  let papersThisYear

  if (selectedThemePubs[0].papers[0].year === parseInt(thisYear)) {
 
  papersThisYear = selectedThemePubs[0].papers.filter(
    (theme) => parseInt(theme.year) === parseInt(thisYear)
  )[0].yearPapers.length;
  } else {
    papersThisYear = 0
  }
  
  let pageDisplay;

  if (allDetails) {
    pageDisplay = (
      <>
      <div className="header">   
        <DisplayThemeHeader themeDetails={themeDetails[0]} />
        </div> 

        <div className="displayDataContainer">
          <div className="nonPublicationsContainer">
            <ThemeSidebarSummary
              themeDetails={themeDetails[0]}
              papersThisYear={papersThisYear}
            />
          </div>

          <div className="publicationsContainer">
            <button className="displayDataButton" onClick={() => setDisplayData(!displayData)}>{!displayData ? `Display Theme Comparison & Trends Data` : `Hide Graphs`}</button>
            
            
            {(themeDetails.length && displayData) ? (
         <>
         <h3 className="subHeading">Theme Summary</h3>
                
                <div className="themeCharts">
                  <ThemePubsTotalChart
                    themeDetails={allDetails}
                    themeColor={theme}
                  />
                  <ThemePubsPerMemberChart
                    themeDetails={allDetails}
                    themeColor={theme}
                  />
                </div>
            </>
            ) : null}

            {(allDetails.length && displayData) ? (
             <div className="staffStats">
                <div className="themeCharts">
                <ThemePubsThisYearChart
                    themePubs={themePubsByYear}
                    themeColor={theme}
                  />                
                  <ThemePubsByYearChart
                    themePubsByYear={selectedThemePubs[0]}
                  />
                </div>
                </div> 
            ) : null}
            

            <div className="papersDisplay">
            <h3 className="pubsHeading">Publications</h3>
            {selectedThemePubs.length ? (
              <DisplayThemePublications
                paperList={selectedThemePubs[0].papers}
              />
            ) : null}
          </div>
          </div>
        
        </div>
      </>
    );
  }

  return pageDisplay

  
};

export default DisplayTheme;
