import {Bar} from 'react-chartjs-2';
import "./StaffPubsTotalChart.css"
import dayjs from 'dayjs'

const StaffPubsPerYear= ({staffPubs, staffColor}) =>{
  const staffPubsSorted = staffPubs.sort((a,b) => b.publications.length - a.publications.length)
    let names =[]
    let papersData = []
   if (staffPubs.length) {
    
    staffPubsSorted.forEach(item => {
      let date1 = dayjs()
      let date2 = dayjs(item.dateStarted)
    
    let diff = date1.diff(date2, 'year', true).toFixed(2)
    let papersPerYear = (item.publications.length/diff).toFixed(2)

    names.push(item.firstName)
    papersData.push(papersPerYear) 
    })
  }

   
   let zipArray=names.map((name,i) => {
    return [name, papersData[i]]
  })
  let zipArraySort = zipArray.sort((a,b) => b[1] - a[1])
  console.log(zipArray)
  let names1 = zipArraySort.map(arr => arr[0])
  let papersData1 = zipArraySort.map(arr => arr[1])
  
  let colorArray = new Array(names1.length).fill("#2ca3d")
  
  if (staffColor && names1.length) {
    const nameIndex = names1.findIndex(name =>  name === staffColor)
    colorArray.splice(nameIndex, 1, "#b96f7e")
  }




       const data = {
            labels: names1,
            datasets: [
              {
                label: 'Staff publications total',
                backgroundColor: colorArray,
                borderColor: 'rgb(48, 174, 239)',
                borderWidth: 2,
                hoverBackgroundColor: 'rgb(147,202,69)',
                hoverBorderColor: 'rgb(70,182,86)',
                data: papersData1
              }
            ]
          };

        return (
            <div className="staffPapersChart">
              <div className="chartHeading">Staff papers per year</div>
              <Bar
                data={data}
                options={{
                  maintainAspectRatio: false,
                  aspectRatio: 2,
                  barPercentage: 0.8,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />
              {/* <p className="barInstructions">Mouse over bar for stats</p> */}
            </div>
          );
    }

export default StaffPubsPerYear