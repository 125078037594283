import './DisplayStaffPublications.css'


const DisplayStaffPublications = ({paperList, displayDates}) => {
  paperList.sort((a,b) => b.id - a.id).sort((a,b) => b.pubdate - a.pubdate)

  let listDisplay = ""
  
    if (paperList.length) {
    listDisplay = paperList.map((data, idx) => {
      let volume = "";
      if (data.volume === "") {
        volume = " volume/pages not yet available";
      } else {
        volume = `${data.volume}: `;
      }

      return (
        <div className="paperlistItem" key={data.id}>
          <div className="listDateHeading">
          {(idx === 0 && displayDates) && <h4 className="listDateItem">{data.pubdate}</h4>}
          {(idx > 0 && displayDates) ? (data.pubdate !== paperList[idx-1].pubdate) && <h4 className="listDateItem">{data.pubdate}</h4> : null}
          </div>
          <span className="paperListSpan">
            <span className="title">{`${data.title} `}</span>
            <span className="authors">{`${data.authors}, `}</span>
            <span className="year">{`(${data.pubdate}), `}</span>
            <span className="journal">{`${data.journal},  `}</span>
            <span className="volume">{`${volume} `}</span>
            <span className="pages">{`${data.pages},  `}</span>
            <span className="doi">{`${data.doi}, `}</span>
            <span className="pmid">
              PMID:{" "}
              <a
                href={`https://www.ncbi.nlm.nih.gov/pubmed/${data.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.id}.
              </a>
            </span>
          </span>
          <span className="tagSpan">
          {data.tags.length ?
          data.tags.map(tag => <span className={tag.style} key={tag.tagName}>{tag.tagName}</span>
            ) : null}
            </span>
          
        </div>
      );
    });
  } 
  
  return listDisplay
};

export default DisplayStaffPublications