import {Bar} from 'react-chartjs-2';
import "./StaffPubsTotalChart.css"

const StaffPubsThisYear = ({staffPubs, staffColor}) =>{
    const thisYear = new Date().getFullYear();
    
    let names =[]
    let papersData = []
    let paperObjs = {}
   
    if (staffPubs.length) { 

    staffPubs.forEach(item => {
      let idx = item.papersByYear.findIndex(el => parseInt(el.year) === parseInt(thisYear))
    if (idx < 0) {
        paperObjs[item.firstName]= 0
    } else {
        paperObjs[item.firstName] = item.papersByYear[idx].papers.length
    }
    })
   }
   
   const arrayObjs = Object.entries(paperObjs).sort((a,b) => b[1] - a[1])
   arrayObjs.forEach(item => {
    names.push(item[0])
    papersData.push(item[1])
   })

   let colorArray = new Array(names.length).fill("#2ca3d")
  
   if (staffColor && names.length) {
     const nameIndex = names.findIndex(name =>  name === staffColor)
     colorArray.splice(nameIndex, 1, "#b96f7e")
   }
 
       const data = {
            labels: names,
            datasets: [
              {
                label: 'Staff publications this year',
                backgroundColor: colorArray,
                borderColor: 'rgb(48, 174, 239)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgb(147,202,69)',
                hoverBorderColor: 'rgb(70,182,86)',
                data: papersData
              }
            ]
          };

        return (
            <div className="staffPapersChart">
              <div className="chartHeading">Staff papers this year</div>
              <Bar
                data={data}
                width={250}
                height={100}
                options={{
                  maintainAspectRatio: false,
                  aspectRatio: 2,
                  barPercentage: 0.8,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />
              {/* <p className="barInstructions">Mouse over bar for stats</p> */}
            </div>
          );
    }

export default StaffPubsThisYear

