import React, { useState, useEffect } from "react";
import axios from 'axios'
import {Redirect} from 'react-router-dom'
import * as instActions from "../store/actions/institute";
import * as staffActions from "../store/actions/staff";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
// import * as actions from './store/actions/staff'
import "./CreateStaffMember.css";


const CreateAdmin = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
 
  const [created, setCreated] = useState(false)
  const [loading, setLoading] = useState(false);
  

    const dispatch = useDispatch()


  const sendEmail = (email, hash) => {
    axios.post("/sendAdminEmail",{email, hash})
    .then(response => {
        alert("Registration email sent!")
    })
    .catch(err => console.log("error"))
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()
    const staffMember = {firstName, lastName, email, permissions: "admin"}
    axios.post("/createAdmin",{staffMember})
    .then(response => {
      sendEmail(response.data.admin.email, response.data.hash)
    })
    .then(
      setCreated(true),
      setLoading(false)
    )
    .catch(err => console.log("error"))
  };


useEffect(() => {
  if (created){
  setLoading(true);
  dispatch(staffActions.getAllStaff())
    .then(() => setLoading(false))
  }
}, [created]);



let pageDisplay
if (loading) {
  pageDisplay = (
    <div className="loaderContainer">
      <Loader
        type="ThreeDots"
        color="#2ca3da"
        height={80}
        width={80}
        className="loader"
      />
      <h1 className="loaderLabel">Loading Data...</h1>
    </div>
  );
} else if (!created) { 
pageDisplay =
<div className="createStaffContainer">
  <h1 className="createStaffHeading">Create a new administrator</h1>
      <form onSubmit={handleSubmit}>
     
      <div className="labelFormGroup">
            <label htmlFor="firstName" className="formLabel">
              First Name
            </label>
            <input
              required
              type="text"
              className="textInputStaff"
              name="firstName"
              value={firstName}
              placeholder="Enter First Name"
              onChange={(evt) => setFirstName(evt.target.value)}
            />
        </div>
          <div className="labelFormGroup">
            <label htmlFor="lastName" className="formLabel">
              Last Name
            </label>
            <input
            required
              type="text"
              className="textInputStaff"
              name="lastName"
              value={lastName}
              placeholder="Enter Last Name"
              onChange={(evt) => setLastName(evt.target.value)}
            />
          </div>
  
          <div className="labelFormGroup">
            <label htmlFor="firstName" className="formLabel">
              Email
            </label>
            <input
            required
              type="email"
              className="textInputStaff"
              name="email"
              value={email}
              placeholder="Enter email address"
              onChange={(evt) => setEmail(evt.target.value.toLowerCase())}
            />
          </div>
     
          <input type="submit" value="Submit" className="createStaffSubmit"/>

      </form>
      
    </div>
    } else if (created){ 
    pageDisplay = 
    <Redirect push to="/adminHome"/>
    }


  return (
    pageDisplay   
  );
};

export default CreateAdmin;
