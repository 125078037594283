import axios from 'axios'
import {calcStaffStats} from "./calcStats"
export const CREATE_STAFF_MEMBER = 'CREATE_STAFF_MEMBER'
export const SELECTED_STAFF_MEMBER = 'CREATE_STAFF_MEMBER'
export const GET_STAFF = 'GET_STAFF'
export const INSTITUTE_PUBLICATIONS = "INSTITUTE_PUBLICATIONS"
export const UPDATE_LOGIN_STAFF_MEMBER = "UPDATE_LOGIN_STAFF_MEMBER"

export const createStaffMember = (staffMember) => {
    return ( { type: CREATE_STAFF_MEMBER, staff: staffMember})
}

export const getAllStaff = () => {
    let allStaff = []
    return async (dispatch) => {
        await axios.get("/getStaff")
        .then(response => allStaff = calcStaffStats(response.data.staff))
        .catch(error => console.log("Error" + error)) 
        await dispatch( { type: GET_STAFF, allStaff: allStaff.allStaffUpdated})
        await dispatch( { type: INSTITUTE_PUBLICATIONS, themesPubsByYear: allStaff.themesByYear, themeDetails: allStaff.themeDetails })
        // await dispatch( { type: INSTITUTE_PUBLICATIONS, institutePubsByYear: allStaff.instituteByYear, themesPubsByYear: allStaff.themesByYear })
    }
}

export const selectedStaffMember = (staffMember) => {
    return ( { type: SELECTED_STAFF_MEMBER, staffMember})
}

export const updateLoggedInUser = (id) => {
    return async (dispatch) => {
      await axios
        .get(`/getLoggedInStaffMember/${id}`)
        .then((response) => {
            dispatch({
              type: UPDATE_LOGIN_STAFF_MEMBER,
              loginStaffMember: response.data.staffMember
            });
        })
        .catch((err) => {
          alert("There was an issue retrieving staff data");
        });
    };
  };