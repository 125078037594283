import "./DisplayThemeHeader.css";

const ThemeHeader = ({ themeDetails }) => {
  console.log(themeDetails);
  const themeLeader = themeDetails.members.filter(
    (member) => member.position === "Theme Leader"
  );
  console.log(themeLeader);
  const labHeads = themeDetails.members.filter(
    (member) => member.position === "Laboratory Head" || "Theme Leader"
  );

  const labHeadsDisplay = (
    <div className="labHeadDisplay">
      {labHeads.map((head, idx) => {
        if (idx !== labHeads.length - 1) {
          return (
            <p
              className="headerDetailText"
              key={idx}
            >{`${head.firstName} ${head.lastName}, `}</p>
          );
        } else {
          return (
            <p
              className="headerDetailText"
              key={idx}
            >{`${head.firstName} ${head.lastName}`}</p>
          );
        }
      })}
    </div>
  );

  const labsDisplay = (
    <div className="labHeadDisplay">
      {themeDetails.members.map((lab, idx) => {
        if (idx !== themeDetails.members.length - 1) {
          return (
            <p className="headerDetailText" key={lab.laboratory}>
              {`${lab.laboratory},`}{" "}
            </p>
          );
        } else
          return (
            <p
              className="headerDetailText"
              key={lab.laboratory}
            >{`${lab.laboratory}`}</p>
          );
      })}
    </div>
  );

  return (
    <div className="themeHeader">
      <h1 className="headerHeadText">{`${themeDetails.theme}`}</h1>
      {themeDetails.theme === "Cancer Biology and Therapy" ? (
        <h3 className="headerSubText">{`Theme Leaders: ${themeLeader[0].firstName} ${themeLeader[0].lastName}, ${themeLeader[1].firstName} ${themeLeader[1].lastName} `}</h3>
      ) : (
        <h3 className="headerSubText">{`Theme Leader: ${themeLeader[0].firstName} ${themeLeader[0].lastName}`}</h3>
      )}
      <div className="labHeads">
        <p className="headerDetailText">Lab Heads: </p>
        {labHeadsDisplay}
      </div>
      <div className="labHeads">
        <p className="headerDetailText">Labs: </p>
        {labsDisplay}
      </div>
    </div>
  );
};

export default ThemeHeader;
