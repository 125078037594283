import {Bar} from 'react-chartjs-2';
import "./ThemePubsPerMemberChart.css"

const ThemePubsPerMember = ({themeDetails, themeColor}) =>{
   
    let themesDataCombined = []
    let themes =[]
    let papersData = []
  
    if (themeDetails.length) {
        themeDetails.forEach(item => {
        themesDataCombined.push({theme: item.theme, averagePapers: item.totalPapers.length/item.members.length}) 
        })
      }
    
    const themeAveragesSorted = themesDataCombined.sort((a,b) => b.averagePapers-a.averagePapers)
    themeAveragesSorted.forEach(item => {
    themes.push(item.theme)
    papersData.push(item.averagePapers)  
    })
  
  let colorArray = new Array(themes.length).fill("#2ca3d")
  
  if (themeColor && themes.length) {
    const themeIndex = themes.findIndex(theme =>  theme === themeColor)
    colorArray.splice(themeIndex, 1, "#b96f7e")
  }

       const data = {
            labels: themes,
            datasets: [
              {
                label: 'Average papers per theme member',
                backgroundColor: colorArray,
                borderColor: 'rgb(48, 174, 239)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgb(147,202,69)',
                hoverBorderColor: 'rgb(70,182,86)',
                data: papersData
              }
            ]
          };

        return (
            <div className="chartContainer4">
               <div className="chartHeading">Theme papers per member</div>
              <Bar
                data={data}
                options={{
                  // indexAxis: "y",
                  maintainAspectRatio: false,
                  aspectRatio: 2,
                  barPercentage: 0.8,
                  plugins: {
                    legend: {
                      display: false
                    }
                  } 
                }}
              />
              {/* <p className="barInstructions">Mouse over bar for stats</p> */}
            </div>
          );
    }

export default ThemePubsPerMember

