import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"
import "./Menubar.css";

const Menubar = () => {
  const permissions = useSelector(state => state.auth.permissions)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const loggedInName= useSelector(state => state.allStaff.loginStaffMember.firstName)

    let bar = 
      <nav className="nav">
        <div className="links">
        {(permissions === "director" || permissions === "exec") &&
        <>
        <Link to="/" className="link">
            HOME
          </Link>
          <Link to="/labThemeSelect" className="link">
          Lab & Theme Data
        </Link>
        </>
}
        {(permissions === "researcher" || permissions === "director") &&
         <Link to="/researcherPortal" className="link">
         My Lab Portal
        </Link>
        }
        {/* {(permissions === "director") ?
          <Link to="/researcherPortal" className="link">
            My Lab Portal
          </Link>
          : null
        } */}
          {(permissions === "admin") &&
          <Link to="/adminHome" className="link">
            Administration Home
          </Link>
        }
        </div>
        

    {isLoggedIn && 
        <div className="loginOut">
          <Link to="/logout" className="link">
            LOGOUT
          </Link>
          <p className="link">{`Logged in as ${loggedInName}`}</p>
          </div>
        }
      </nav>
      

  return bar
  
};

export default Menubar;
