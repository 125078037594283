import React, {useState, useEffect} from 'react'
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as allActions from '../store/actions/auth'
import DisplayStaffMemberHeader from "./DisplayStaffMemberHeader";
import fileDownload from 'js-file-download'
import "./DisplayResearchStaffPortal.css"
import axios from 'axios';
import * as FileSaver from "file-saver";

const DisplayResearchStaffPortal = () => {
    const [action, setAction] = useState("")
    const [authError, setAuthError] = useState(false)
    const selectedStaffMember = useSelector(state => state.allStaff.loginStaffMember)
    const permissions = useSelector(state => state.auth.permissions)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const {grants, students, industry, committees, patents, clinical, engagement} = selectedStaffMember


    const {
        firstName,
        lastName,
        photoPath,
        laboratory,
        program,
        position,
        dateStarted,
      } = selectedStaffMember;

      const selectedTheme = selectedStaffMember.theme

      const dispatch = useDispatch()


      const getFile= (fileName) => {
        axios.get(`/getResourceUrl/${fileName}`,{
          responseType: 'blob',
        })
        .then((res) => {
          var file = new File([res.data], `${fileName}.xlsx`, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
          FileSaver.saveAs(file)
          alert('Your can find your spreadsheet (Upload Form.xlsx)in your downloads folder')
      }
    )}
    

      // const handleDownload = (url, filename) => {
      //   axios.get(url, {
      //     responseType: 'blob',
      //   })
      //   .then((res) => {
      //     fileDownload(res.data, filename)
      //   })
      // }


      useEffect(() => {
        if(isLoggedIn && (permissions === "researcher" || permissions === "director")){
         return 
        } else {
          dispatch(allActions.resetAuth())
          setAuthError(true)
        }
      }, [])



      let pageDisplay
      if(!action && !authError) {
          pageDisplay=
      <>
        <div className="staffHeadSection"> 
        <DisplayStaffMemberHeader
          staffDetails={{
            firstName,
            lastName,
            photoPath,
            laboratory,
            program,
            position,
            dateStarted,
          }}
        /> 
       </div>
        <div className="portalContainer">
      <div className="portalSelectionContainer">
      <div className="portalSelection">
      <h1 className="portalHeader">Data</h1>
      <button className="adminButton" onClick={() => setAction("staff")}>Your Data</button>
      <button className="adminButton" onClick={() => setAction("theme")}>Your Theme Data</button>
      </div>
      
      {/* <hr/> */}

      <div className="portalSelection">
      <h1 className="portalHeader">Administration</h1>

      <button
  className="adminButton"
  onClick={() => setAction("add")}
  >
  Add Publications
  </button>

      {grants?.length || students?.length || industry?.length || committees?.length || patents?.length || clinical?.length || engagement?.length ?
            <button className="adminButton" onClick={() => setAction("update")}>
            Update Non-Publication Data
            </button>
            :
            <button className="adminButton" onClick={() => setAction("upload")}>
           Upload All Non-Publication Data
          </button>
      }

      <hr/>
      <h2 className="portalHeader">Resources</h2>
        <button className="adminButton" name="Upload Form" onClick={(evt) => getFile(evt.target.name)}>
            Download Data Upload Form
            </button>

  </div>
  </div>
  </div>
  </>
    } else if (authError) {
      alert("Sorry you either have not logged in or do not have permission to access this page. You be redirected to the login page. If the error persists please contact your administrator as your permissions may need to be changed.")
      pageDisplay=
      <Redirect  
      to={"/login"}
      />
    } else if (action === "staff") {
      pageDisplay=
      <Redirect  
      push
      to={{
          pathname: "/displayStaffMember",
          state: { selectedStaffMember },
      }}
      />
      } else if (action === "theme")   {
        pageDisplay=
        <Redirect  
        push
        to={{
            pathname: "/displayTheme",
            state: { selectedTheme },
        }}
        />
      } else if (action === "add")   {
        pageDisplay=<Redirect push to={"/createPublicationList"}/>
      }  else if (action === "upload")   {
        pageDisplay=<Redirect push to={"/uploadAllData"}/>
      }  else if (action === "update")   {
        pageDisplay=<Redirect push to={"/updateData"}/>
      }
      
    return pageDisplay
      
}

export default DisplayResearchStaffPortal