import React, { useState } from "react";
import "./AdminSelectedStaffMember.css";
import LabThemeSelectHeader from "../displayComponents/LabThemeSelectHeader";
import {Redirect} from 'react-router-dom'

const AdminSelectedStaffMember = (props) => {
  const selectedStaffMember = props.location.state.selectedStaffMember;
  const [action, setAction] = useState("");

  let pageDisplay

  if(!action){
pageDisplay=
  <div className="adminSelectedStaffContainer">
  <LabThemeSelectHeader
    pageName={`${selectedStaffMember.firstName} ${selectedStaffMember.lastName} Administration Page`}
  />
    <button className="adminButton" onClick={() => setAction("uploadAll")}>
    Upload All Non-Publication Data
    </button>
   
   
    <button
    className="adminButton"
    onClick={() => setAction("addPublications")}
    >
    Add Publications
    </button>

  {/* <button className="adminButton" onClick={() => setAction("addStudents")}>
    Add a Student
  </button>
  <button className="adminButton" onClick={() => setAction("addGrants")}>
    Add a Grant
  </button>
  <button
    className="adminButton"
    onClick={() => setAction("addCommittees")}
  >
    Add a Committee
  </button>
  <button className="adminButton" onClick={() => setAction("addIndustry")}>
    Add an Industry Collaboration
  </button> */}
</div>
  } else if (action === "uploadAll") {
    pageDisplay = (
      <Redirect
        to={{
          pathname: "/uploadAllData",
          state: { selectedStaffMember },
        }}
      />
    );
  } else if (action === "addStudents") {
    pageDisplay = (
      <Redirect
        to={{
          pathname: "/createStudents",
          state: { selectedStaffMember },
        }}
      />
    );
  } else if (action === "addGrants") {
    pageDisplay = (
      <Redirect
        to={{
          pathname: "/createGrants",
          state: { selectedStaffMember },
        }}
      />
    );
  } else if (action === "addIndustry") {
    pageDisplay = (
      <Redirect
        to={{
          pathname: "/createIndustry",
          state: { selectedStaffMember },
        }}
      />
    );
  } else if (action === "addPublications") {
    pageDisplay = (
      <Redirect
        to={{
          pathname: "/createPublicationList",
          state: { selectedStaffMember },
        }}
      />
    );
  } else if (action === "addCommittees") {
    pageDisplay = (
      <Redirect
        to={{
          pathname: "/createCommittees",
          state: { selectedStaffMember },
        }}
      />
    );
  }
  
  return (
   pageDisplay
  );
};

export default AdminSelectedStaffMember;
