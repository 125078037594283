import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetPapersLocal} from "./GetPapersLocal"
import axios from "axios";
import Loader from "react-loader-spinner";
import PublicationDisplay from "./PublicationDisplay";
import * as allActions from "../store/actions/papers";
import { Redirect } from "react-router-dom"
import "./CreatePublicationList.css";
import * as instActions from "../store/actions/institute";
import * as staffActions from "../store/actions/staff";
import dayjs from 'dayjs'


const CreatePublicationList = (props) => {
  const loginStaffMember = useSelector(state => state.allStaff.loginStaffMember);
  const convertedDate = dayjs(loginStaffMember.dateStarted).format("YYYY-MM-DD")
  const instStartYear = useSelector(
    (state) => state.institute.instituteStartYear
  );
  // let searchList = useSelector((state) => state.papers.paperList);

  const [pubmedName, setPubmedName] = useState("");
  const [dateStarted, setDateStarted] = useState(convertedDate);
  const [nonONJ, setNonONJ] = useState(false);
  // const [nameCreated, setNameCreated] = useState(false);
  const [created, setCreated] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [loading, setLoading] = useState(false);

  const [paperList, setPaperList] = useState([]);
  const dispatch = useDispatch();

  const claimPaper = (paperId) => {
    const idx = paperList.findIndex((paper) => paper.id === paperId);
    let newPaperList = [...paperList];
    let listItem = paperList[idx];
    if (!paperList[idx].claimed) {
      listItem.claimed = true;
      newPaperList[idx] = listItem;
    } else {
      listItem.claimed = false;
      newPaperList[idx] = listItem;
    }
    setPaperList(newPaperList);
  
  };

  const addTag = (paperId, name) => {
    const idx = paperList.findIndex((paper) => paper.id === paperId);
    let newPaperList = [...paperList];
    let listItemTags = paperList[idx].tags;
 
  if(listItemTags.length) {
    let listItemTagValues = listItemTags.map(tag => tag.tagName)
    if(!listItemTagValues.includes(name.tagName)){
        listItemTags.push(name);
    } else {
        listItemTags = listItemTags.filter((tag) => tag.tagName !== name.tagName);
    }
  } else {
    listItemTags.push(name);
  }
  newPaperList[idx].tags = listItemTags;
  setPaperList(newPaperList);
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true)
    const newPaperList = async () => GetPapersLocal(pubmedName, dateStarted, nonONJ)
    newPaperList()
    .then(value => {

      if (value.length && value !=="lengthError") {
      value.sort((a,b) => b.id - a.id)
      setPaperList(value)
      setLoading(false)
      } else if (value ==="lengthError"){
        alert("Too many papers retrieved. Try to narrow the search dates")
        setPubmedName("")
        setDateStarted("")
        setNonONJ(false)
        setLoading(false)
      } else {
      alert("No publications identified with those parameters. Enter another search name or seatch start data")
      setPubmedName("")
      setDateStarted("")
      setNonONJ(false)
      setLoading(false)
      }
  
    })
  };

  // const handleSubmit = (evt) => {
  //   evt.preventDefault();
  //   setLoading(true);
  //   dispatch(allActions.getPapers(pubmedName, dateStarted)).then(() =>
  //     setLoading(false)
  //   );
  // };

  const handleSubmitPublications = (evt) => {
    evt.preventDefault();
    setLoading(true);
    let publicationList = paperList.filter((paper) => paper.claimed);
    let idList = []
    publicationList.forEach(pub => idList.push(pub.id))
    axios
      .patch("/addPublications", { id: loginStaffMember._id, publicationList, idList })
      .then((response) => alert("Publications Added"))
      .then(dispatch(allActions.removePapers()))
      .then(
      setLoading(false),  
      setCreated(true)  
      )
      .catch((err) => console.log(err))
      }

  // useEffect(() => {
  //   setPaperList(searchList);
  // }, [searchList]);


  useEffect(() => {
    if(created){  
    setLoading(true);
      dispatch(staffActions.getAllStaff())
        .then(() =>
          dispatch(
            instActions.getAllPaperData(
              process.env.REACT_APP_INSTITUTE_NAME,
              instStartYear
            )
          )
        )
        .then(() => dispatch(staffActions.updateLoggedInUser(loginStaffMember._id)))
        .then(() => setUpdated(true))
        .then(() => setLoading(false))
    }
  }, [created]);




  let pageDisplay = "";
  if (loading) {
    pageDisplay = 
      <>
        <Loader type="ThreeDots" color="#2ca3da" height={80} width={80} className="pubsearchLoader"/>
        <h1 className="loaderLabel">Fetching papers...</h1>
      </>;
  } else if (!loading && !created) {

    pageDisplay = (
      <>
        <h1 className="pageHeading">Create Publication List</h1>

        
        {!paperList.length ? (
          <div className="searchFormContainer">
            <div className="searchPubsSubHeadings">
            <p className="searchSubheading">Enter your search details to retrieve your ONJCRI papers</p>
            <p className="searchSubheadingAffiliation">Check "Search all affiliations" if you think your papers could have issues with your ONJCRI affiliation spelling, listing etc.</p>
            </div>
            <form onSubmit={handleSubmit} className="publicationSearchForm">
            
            <div className="formLabelInput">
              <label className="createPublicationFormLabel">Pubmed search name
              </label>
              <input
                required
                type="text"
                className="formInput"
                value={pubmedName}
                placeholder="Enter as for Pubmed e.g. Smith AB"
                onChange={(evt) => setPubmedName(evt.target.value)}
              />
            </div>
           
            <div className="formLabelInput">
            <label className="createPublicationFormLabel">Search Start Date (defaults to your ONJCRI start date)
              </label>
              <input
              required
                type="date"
                className="formInput"
                value={dateStarted}
                onChange={(evt) => setDateStarted(evt.target.value)}
              />
          </div>

          <div className="checkboxFormLabelInput">
          <input      
                type="checkbox"
                value={nonONJ}
                onChange={(evt) => setNonONJ(evt.target.checked)}
              />
            
             <label className="checkboxFormLabel"> Search all affiliations
              </label>

          </div>

              <input type="submit" value="Fetch papers..." className="createPubsSubmitButton"/>
            </form>
          </div>
        ) : null}

        {paperList.length ? (
          <div className="publicationsDisplayContainer">
            <p className="searchSubheading2">Claim and tag any papers you want to appear in your publication list. </p>
            <p className="searchSubheading3">Hit submit at the bottom of the page to create the list.</p>
            <PublicationDisplay
              paperList={paperList}
              claimPaper={claimPaper}
              addTag={addTag}
            />
            <form onClick={handleSubmitPublications}>
              <input type="submit" value="Submit" className="createPubsSubmitButton" />
            </form>
          </div>
        ) : null}
      </>
    );
  } else if (updated) {
    pageDisplay= <Redirect to="/researcherPortal"/>
  }

  return (
    <div className="createPublicationsContainer">
      {pageDisplay}
    </div>
  );
};

export default CreatePublicationList;
