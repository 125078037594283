import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import HomeHeader from "./HomeHeader";
import DisplayInstitutePublicationStats from "./DisplayInstitutePublicationStats";
import DisplayStaffPublications from "./DisplayStaffPublications";
import InstitutePubsByYearChart from "../chartComponents/InstitutePubsByYearChart";
import StaffPubsThisYearChart from "../chartComponents/StaffPubsThisYearChart";
import StaffPubsTotalChart from "../chartComponents/StaffPubsTotalChart";
import ThemePubsTotalChart from "../chartComponents/ThemePubsTotalChart";
import ThemePubsThisYearChart from "../chartComponents/ThemePubsThisYearChart";
// import ReportingDropdown from "../adminComponents/ReportingDropdown"

import * as instActions from "../store/actions/institute";
import * as staffActions from "../store/actions/staff";
import * as authActions from "../store/actions/auth";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import "./Home.css";
// import ThemePubsTotal from "./chartComponents/ThemePubsTotalChart";

const Home = () => {

  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [authError, setAuthError] = useState(false);

  
  const pubsLast7days = useSelector((state) => state.institute.pubsLast7days);
  const pubsThisYear = useSelector((state) => state.institute.pubsThisYear);
  const allPubs = useSelector((state) => state.institute.allPubs);
  const pubsByYear = useSelector((state) => state.institute.pubsLastFive);
  const themeDetails = useSelector((state) => state.institute.themeDetails);
  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const token = useSelector((state) => state.auth.token);
  const permissions = useSelector(state => state.auth.permissions)
  
  
  // const [startDate, setStartDate]=useState("")
  // const [timeFrame, setTimeFrame] = useState(7)
  // const [getPapers, setGetPapers] = useState(false)
  
  const themesPubsByYear = useSelector(
    (state) => state.institute.themesPubsByYear
  );
  const instStartYear = useSelector(
    (state) => state.institute.instituteStartYear
  );
  const staffPubs = useSelector((state) => state.allStaff.staff);
    const dispatch = useDispatch();

//   const handleStartDate = (evt) => {
//     const calcStartDate = dayjs().subtract(evt.target.value, 'day').format("YYYY-MM-DD")
//     setStartDate(calcStartDate)
//   }


//   useEffect(() => {
//     if(getPapers){
//       const endDate = dayjs().format("YYYY-MM-DD")
//       setLoading(true)
//   dispatch(allActions.getReportPapers(startDate,endDate))
//   .then(() => setLoading(false))
//     } else {
//         return
//     }
// },[getPapers])

useEffect(() => {
  if (isLoggedIn && token) { 
  axios.post("/tokenIsValid", null, {headers: {"x-auth-token": token}})
  .then(response => {
    if (response.data){
      setLoggedIn(true)
      setLoading(false)
    } else {
      alert("Your previous session has expired - you will be redirected to the login page")
      const result = async () => dispatch(authActions.resetAuth())
      result()
      setAuthError(true)
      setLoading(false) 
    }
  })
  } else {
    setAuthError(true)
    setLoading(false) 
  }
}, [])



  useEffect(() => {
    if(isLoggedIn){
      setLoading(true);
      dispatch(staffActions.getAllStaff())
        .then(() => 
          dispatch(
            instActions.getInstituteData(process.env.REACT_APP_INSTITUTE_NAME)
          ))
        .then(() =>
          dispatch(
            instActions.getAllPaperData(
              process.env.REACT_APP_INSTITUTE_NAME,
              instStartYear
            )
          )
        )
        .then(() => setLoading(false));  
  }
else {
  setAuthError(true)
    setLoading(false) 
}
}, [loggedIn]);

  let pageDisplay;

  if (loading) {
    pageDisplay = (
      <div className="loaderContainer">
        <Loader
          type="ThreeDots"
          color="#2ca3da"
          height={80}
          width={80}
          className="loader"
        />
        <h1 className="loaderLabel">Loading Data...</h1>
      </div>
    );
  } else if (loggedIn && (permissions === "director" || permissions === "exec")){
    pageDisplay = (
     <>
        <div className="homePageHeader">
        <HomeHeader />
        </div>

       
       <div className="homePageContainer">
        {pubsLast7days && pubsThisYear && pubsByYear ? (
          <div className="institutePubStats">
            <DisplayInstitutePublicationStats
              pubsLast7days={pubsLast7days.length}
              pubsThisYear={pubsThisYear}
              allPubs={allPubs}
            />
          </div>
        ) : null}

        <div className="pubsAndCharts">
        {/* <div className="selectBoxContainer">
          <div className="selectBox" onClick={() => setPage("data")}>
            <p>Data</p>
          </div>
          <div className="selectBox" onClick={() => setPage("admin")}>
            <p>Administration</p>
          </div>
        </div> */}

        <>
            <h3 className="topSubHeading">Publications this week</h3>
            {/* <ReportingDropdown handleStartDate={handleStartDate}/> */}
        {pubsLast7days.length ? (
          <div className="paperListDisplay">
            <DisplayStaffPublications
              paperList={pubsLast7days}
              displayDates={false}
            />
        </div>
        ) : <p className="noPaperMessage">No ONJCRI papers published this week.</p>}
             </>

          {pubsByYear.length ? (
          <div className="staffStatsContainer">
            <h3 className="subHeading">Institute Summary</h3>
            <div className="statCharts">
            <InstitutePubsByYearChart pubsByYear={pubsByYear} />
            </div>
          </div>
        ) : null}



        {staffPubs.length ? (
          <div className="staffStatsContainer">
            <h3 className="subHeading">Staff Summary</h3>
            <div className="statCharts">
            <StaffPubsTotalChart staffPubs={staffPubs} />
              <StaffPubsThisYearChart staffPubs={staffPubs} />
            </div>
          </div>
        ) : null}


        {themeDetails.length && themesPubsByYear.length ? (
          <div className="staffStatsContainer">
            <h3 className="subHeading">Theme Summary</h3>
            <div className="themeCharts">
              <ThemePubsTotalChart themeDetails={themeDetails} />
              <ThemePubsThisYearChart themePubs={themesPubsByYear} />
            </div>
          </div>
        ) : null}
      </div>
      </div>
      </>
    );
  } else if (loggedIn && (permissions === "researcher")){
    pageDisplay = <Redirect to="/researcherPortal"/>
  } else if (authError){
    pageDisplay = 
    <Redirect push to="/login" />;
  }

  return  pageDisplay
};

export default Home;
