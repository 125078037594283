import './LabThemeSelectHeader.css'

const LabThemeSelectHeader = ({pageName}) => {
    return(
        <div>
            <div className="bannerContainer">
            <img src="./client/src/ONJCRI.jpg" alt="logo" className="bannerImage"/>
            <div className="bannerTitleContainer">
                <p className="bannerTitle">{pageName}</p>
            </div>
            </div>
        </div>
        
    )

}
// client/src/ONJCRI.jpg
export default LabThemeSelectHeader