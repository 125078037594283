import "./StaffDataSummary.css"


const StaffDataSummary = ({pubNumber, internalNumber, grantNumber, studentNumber, industryNumber, patentNumber, clinicalNumber, engagementNumber}) => {

    return (
        <div className="headerSummaryContainer">
            <div className="summaryItemContainer">
                <div className="summaryItemNumberBubble">
                  <p className="amount">{pubNumber || 0}</p>  
                </div>
                <p className="summaryItemLabel">Publications</p>
            </div>
            <div className="summaryItemContainer">
                <div className="summaryItemNumberBubble">
                  <p className="amount">{internalNumber || 0}</p>  
                </div>
                <p className="summaryItemLabel">Internal</p>
            </div>
            <div className="summaryItemContainer">
                <div className="summaryItemNumberBubble">
                  <p className="amount">{`${grantNumber}` || 0}</p>  
                </div>
                <p className="summaryItemLabel">Grants ($M)</p>
            </div>
            <div className="summaryItemContainer">
                <div className="summaryItemNumberBubble">
                  <p className="amount">{studentNumber || 0}</p>  
                </div>
                <p className="summaryItemLabel">Students</p>
            </div>
            <div className="summaryItemContainer">
                <div className="summaryItemNumberBubble">
                  <p className="amount">{engagementNumber || 0}</p>  
                </div>
                <p className="summaryItemLabel">Engagement</p>
            </div>
            <div className="summaryItemContainer">
                <div className="summaryItemNumberBubble">
                  <p className="amount">{industryNumber || 0}</p>  
                </div>
                <p className="summaryItemLabel">Industry Collabs</p>
            </div>
            <div className="summaryItemContainer">
            <div className="summaryItemNumberBubble">
                  <p className="amount">{patentNumber || 0}</p>  
                </div>
                <p className="summaryItemLabel">Patents</p>
             </div>   
             <div className="summaryItemContainer">
            <div className="summaryItemNumberBubble">
                  <p className="amount">{clinicalNumber || 0}</p>  
                </div>
                <p className="summaryItemLabel">Clinical Collabs</p>
             </div>  
            
            
        </div>
    )
}

export default StaffDataSummary