import {Bar} from 'react-chartjs-2';
import "./ThemePubsByYearChart.css"

const ThemePubsByYear = ({themePubsByYear}) =>{
        const themePubsByYearSorted = themePubsByYear.papers.sort((a,b) => a.year-b.year)
        let years =[]
        let papersData = []
        themePubsByYearSorted.forEach(item => {
          years.push(item.year)
          papersData.push(item.yearPapers.length)
        }
        )
        

        const data = {
            labels: years,
            datasets: [
              {
                label: 'Publications By Year',
                backgroundColor: '#2ca3da',
                borderColor: '#2ca3da',
                borderWidth: 1,
                hoverBackgroundColor: 'rgb(147,202,69)',
                hoverBorderColor: '#2ca3da',
                data: papersData
              }
            ]
          };

        return (
            <div className="chartContainer3">
              <div className="chartHeading">Theme papers by year</div>
              <Bar
                data={data}
                options={{
                  maintainAspectRatio: false,
                  aspectRatio: 2,
                  barPercentage: 0.8,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />
              {/* <p className="barInstructions">Mouse over bar for stats</p> */}
            </div>
          );
    }

export default ThemePubsByYear