const YearDropDown = () => {
  const thisYear = new Date().getFullYear();
  const startYear = 2014;
  const yearArray = [];
  for (let i = startYear; i <= thisYear + 5; i++) {
    yearArray.push(i);
  }

  const yearDisplay = yearArray.map((year) => <option key={year} value={year}>{year}</option>);
 
 

  return (
    <>
    <option disabled value="">Select Year</option>
    <option value="current">Current</option>
   { yearDisplay}
    </>  
  )
};

export default YearDropDown;
